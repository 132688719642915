import React from 'react';
import ItemDetail from './ItemDetail';
import Recommendations from './Recommendations';
import Reporting from './Reporting';
import Customer from './Customer';
import Opportunity from './Opportunity';
import { Avatar, AvatarImage, AvatarFallback } from './ui/avatar';
import { User, ChevronRight, Ticket, ScanBarcode, Users } from 'lucide-react';

const Content = ({ searchQuery }) => {
    // Handle filtering based on searchQuery

    return (
        <div className="flex-1 p-4">
            <div className="flex flex-col gap-2">
                <span className="text-gray-800 dark:text-gray-400 col-span-4 font-semibold text-sm mt-4">
                    Latest Metrics
                </span>
                <div className="grid grid-cols-4 gap-4 text-gray-800 dark:text-gray-200">
                    <div className="flex flex-col gap-8 bg-white dark:bg-slate-800 rounded-lg border-[1.4px] border-gray-200 dark:border-slate-700 p-4 shadow-md">
                        <div className="flex justify-between items-center">
                            <span className='text-lg font-semibold'>Suppliers</span>
                            <Avatar className="border-2 dark:border-slate-700">
                                <AvatarFallback className="dark:bg-black">
                                    <User />
                                </AvatarFallback>
                            </Avatar>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-4xl font-bold">324</span>
                            <a href="#" className="hover:text-gray-600 dark:hover:text-gray-400 flex gap-2 items-center text-xs font-semibold text-gray-500">
                                Learn More
                                <ChevronRight className="size-4"/>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col gap-8 bg-white dark:bg-slate-800 rounded-lg border-[1.4px] border-gray-200 dark:border-slate-700 p-4 shadow-md">
                        <div className="flex justify-between items-center">
                            <span className='text-lg font-semibold'>Tickets</span>
                            <Avatar className="border-2 dark:border-slate-700">
                                <AvatarFallback className="dark:bg-blue-500">
                                    <Ticket />
                                </AvatarFallback>
                            </Avatar>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-4xl font-bold">926</span>
                            <a href="#" className="hover:text-gray-600 dark:hover:text-gray-400 flex gap-2 items-center text-xs font-semibold text-gray-500">
                                Learn More
                                <ChevronRight className="size-4"/>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col gap-8 bg-white dark:bg-slate-800 rounded-lg border-[1.4px] border-gray-200 dark:border-slate-700 p-4 shadow-md">
                        <div className="flex justify-between items-center">
                            <span className='text-lg font-semibold'>Purchase Order</span>
                            <Avatar className="border-2 dark:border-slate-700">
                                <AvatarFallback className="dark:bg-amber-500">
                                    <ScanBarcode />
                                </AvatarFallback>
                            </Avatar>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-4xl font-bold">560</span>
                            <a href="#" className="hover:text-gray-600 dark:hover:text-gray-400 flex gap-2 items-center text-xs font-semibold text-gray-500">
                                Learn More
                                <ChevronRight className="size-4"/>
                            </a>
                        </div>
                    </div>
                    <div className="flex flex-col gap-8 bg-white dark:bg-slate-800 rounded-lg border-[1.4px] border-gray-200 dark:border-slate-700 p-4 shadow-md">
                        <div className="flex justify-between items-center">
                            <span className='text-lg font-semibold'>Contacts</span>
                            <Avatar className="border-2 dark:border-slate-700">
                                <AvatarFallback className="dark:bg-red-500">
                                    <Users />
                                </AvatarFallback>
                            </Avatar>
                        </div>
                        <div className="flex justify-between items-center">
                            <span className="text-4xl font-bold">1960</span>
                            <a href="#" className="hover:text-gray-600 dark:hover:text-gray-400 flex gap-2 items-center text-xs font-semibold text-gray-500">
                                Learn More
                                <ChevronRight className="size-4"/>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="grid grid-cols-4 col-span-4 gap-4">
                    <span className="text-gray-800 dark:text-gray-400 col-span-2 font-semibold text-sm mt-8">
                        Customers
                    </span>
                    <span className="text-gray-800 dark:text-gray-400 col-span-2  font-semibold text-sm mt-8">
                        Opportunities
                    </span>
                </div>
                <div className="grid grid-cols-4 gap-4">
                    <div className="col-span-2">
                        <Customer searchQuery={searchQuery} />
                    </div>
                    <div className="col-span-2">
                        <Opportunity searchQuery={searchQuery} />
                    </div>
                </div>
                <span className="text-gray-800 dark:text-gray-400 grid-cols-4 font-semibold text-sm mt-8">
                    Reports
                </span>
                <div className="grid grid-cols-4">
                    <div className="grid grid-cols-4 bg-white dark:bg-slate-800 rounded-lg border-[1.4px] border-gray-200 dark:border-slate-700 p-4 col-span-4">
                        <Reporting searchQuery={searchQuery} />
                        <ItemDetail searchQuery={searchQuery} />
                        <Recommendations searchQuery={searchQuery} />
                    </div>
                </div>
            </div>
        </div>
    );
}

/*
<div className="flex-1 p-4">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
                <div className="col-span-2 space-y-6">
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        
                    </div>
                </div>
                <div className="col-span-1 space-y-6">
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        
                    </div>
                    <div className="bg-white p-6 rounded-lg shadow-md">
                        
                    </div>
                </div>
            </div>
        </div>
*/

export default Content;
