import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_PROJECT_URL;
const supabaseKey = process.env.REACT_APP_SUPABASE_SECRET_KEY;

export const supabase = createClient(supabaseUrl, supabaseKey);

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) {
    console.error('Error signing out:', error);
  } else {
    console.log('Signed out successfully');
  }
};
