import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { cn } from './ui/cn';
import { useToast } from './ui/use-toast';

const Login = ({ onLogin }) => {
    const [isSignUp, setIsSignUp] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const { toast } = useToast()

    const handleAuth = async (e) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            let response;

            if (isSignUp) {
                {/*response = await supabase.auth.signUp({
                    email,
                    password,
                    options: {
                        data: {
                            companyName,
                            name,
                        },
                    },
                });*/}
            } else {
                response = await supabase.auth.signInWithPassword({
                    email,
                    password,
                });
            }

            if (response.error) {
                setError(response.error.message);
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: response.error.message
                })
            } else {
                onLogin(response.data.user);
            }
        } catch (error) {
            setError(error.message);
            toast({
                variant: "destructive",
                title: "Error",
                description: error.message
            })
        } finally {
            setLoading(false);
        }
    };

    const handleGoogleLogin = async () => {
        try {
            const { error } = await supabase.auth.signInWithOAuth({
                provider: 'google'
            });

            if (error) {
                console.error('Error during Google login:', error);
                toast({
                    variant: "destructive",
                    title: "Error",
                    description: 'Error during Google login: ' + error.message
                })
            }
        } catch (error) {
            console.error('Error during Google login:', error);
            toast({
                variant: "destructive",
                title: "Error",
                description: 'Error during Google login: ' + error.message
            })
        }
    };

    return (
        <div className="flex flex-col w-full h-full p-8 bg-white">
            <div className="flex justify-end w-full">
                <button
                    onClick={() => setIsSignUp(!isSignUp)}
                    className="text-gray-800 text-sm font-semibold text-right w-fit"
                >
                    {isSignUp ? "Login" : "Sign Up"}
                </button>
            </div>
            <div className="flex flex-col justify-center items-center w-full h-full">
                <div className="flex flex-col justify-center items-center w-[400px] space-y-6">
                    <div className="flex flex-col space-y-2 items-center">
                        <h2 className="text-2xl font-semibold tracking-tight">{isSignUp ? "Create an account" : 'Login'}</h2>
                        <p className="text-sm text-gray-700">{
                            isSignUp 
                            ? "Enter your details below to create your account"
                            : "Enter your username/email and password"
                        }</p>
                    </div>
                    <form onSubmit={handleAuth} className={cn(
                        "flex flex-col w-full gap-4",
                        isSignUp && "gap-8"
                    )}>
                        <div className="flex flex-col gap-2">
                            {isSignUp && (
                                <Input
                                    id="company"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                    className="h-9"
                                    placeholder="Company Name"
                                    required
                                />
                            )}
                            <Input
                                type="email"
                                id="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder="name@example.com"
                                className="h-9"
                                required
                            />
                        </div>
                        {isSignUp &&
                            <div className="relative">
                                <div className="absolute inset-0 flex items-center">
                                    <span className="w-full border-t"></span>
                                </div>
                                <div className="relative flex justify-center text-xs uppercase">
                                    <span className="bg-background px-4 text-muted-foreground">Additionally</span>
                                </div>
                            </div>}
                        <div className="flex flex-col gap-2">
                            {isSignUp && (
                                <Input
                                    id="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Username"
                                    className="h-9"
                                    required
                                />
                            )}
                            <Input
                                type="password"
                                id="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className="h-9"
                                placeholder="Password"
                                required
                            />
                        </div>
                        <Button
                            type="submit"
                            disabled={loading}
                        >
                            {loading ? 'Loading...' : isSignUp ? 'Sign In with Email' : 'Login'}
                        </Button>
                    </form>
                    <div className="relative w-full">
                        <div className="absolute inset-0 flex items-center">
                            <span className="w-full border-t"></span>
                        </div>
                        <div className="relative flex justify-center text-xs uppercase">
                            <span className="bg-white px-2 text-muted-foreground">Or continue with</span>
                        </div>
                    </div>
                    <Button
                        type="button"
                        variant="outline"
                        className="w-full"
                    >
                        Login with Google
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default Login;