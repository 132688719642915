import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import backend from '../api/axios';
import { DialogClose } from './ui/dialog';
import { useDarkMode } from './new/DarkContext';
import { cn } from './ui/cn';

const EditTicketModal = ({ email, setEmail, onCreateTicket }) => {
    const [ticket, setTicket] = useState({
        supplier: '',
        quantity: '',
        net_price: '',
        priority: '',
        person_assigned: '',
        part_number: '',
        status: 'open',
    });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchParsedTicket = async () => {
            setIsLoading(true);
            try {
                const response = await backend.post('/parse-email-for-ticket', { email });
                setTicket(response.data);
            } catch (error) {
                console.error('Error parsing email:', error);
                // You might want to show an error message to the user
            } finally {
                setIsLoading(false);
            }
        };

        if (email) {
            fetchParsedTicket();
        }
    }, [email]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setTicket(prevTicket => ({ ...prevTicket, [name]: value }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        onCreateTicket(ticket);
    };

    const { dark } = useDarkMode()

    return (
        <div className={cn(
            dark && "bg-slate-700 text-gray-200",
            "rounded overflow-y-auto"
        )}>
            <h2 className="text-xl font-bold mb-4">Edit Ticket Information</h2>
            {isLoading ? (
                email == null
                    ? <p>No email selected</p>
                    : <p>Loading ticket information...</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block mb-2">Supplier</label>
                        <input
                            type="text"
                            name="supplier"
                            value={ticket.supplier}
                            onChange={handleInputChange}
                            className={cn(
                                "w-full outline-none border rounded-lg px-2 text-xs font-semibold h-9",
                                dark && "bg-slate-700 border-slate-600 text-gray-400"
                            )}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2">Quantity</label>
                        <input
                            type="number"
                            name="quantity"
                            value={ticket.quantity}
                            onChange={handleInputChange}
                            className={cn(
                                "w-full outline-none border rounded-lg px-2 text-xs font-semibold h-9 appearance-none",
                                dark && "bg-slate-700 border-slate-600 text-gray-400"
                            )}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2">Net Price</label>
                        <input
                            type="number"
                            name="net_price"
                            value={ticket.net_price}
                            onChange={handleInputChange}
                            className={cn(
                                "w-full outline-none border rounded-lg px-2 text-xs font-semibold h-9 appearance-none",
                                dark && "bg-slate-700 border-slate-600 text-gray-400"
                            )}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2">Priority</label>
                        <select
                            name="priority"
                            value={ticket.priority}
                            onChange={handleInputChange}
                            className={cn(
                                "w-full outline-none border rounded-lg px-2 text-xs font-semibold h-9 appearance-none",
                                dark && "bg-slate-700 border-slate-600 text-gray-400"
                            )}
                        >
                            <option value="Low">Low</option>
                            <option value="Medium">Medium</option>
                            <option value="High">High</option>
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2">Person Assigned</label>
                        <input
                            type="text"
                            name="person_assigned"
                            value={ticket.person_assigned}
                            onChange={handleInputChange}
                            className={cn(
                                "w-full outline-none border rounded-lg px-2 text-xs font-semibold h-9",
                                dark && "bg-slate-700 border-slate-600 text-gray-400"
                            )}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2">Part Number</label>
                        <input
                            type="text"
                            name="part_number"
                            value={ticket.part_number}
                            onChange={handleInputChange}
                            className={cn(
                                "w-full outline-none border rounded-lg px-2 text-xs font-semibold h-9",
                                dark && "bg-slate-700 border-slate-600 text-gray-400"
                            )}
                        />
                    </div>
                    <div className="flex justify-end">
                        <DialogClose>
                            <button
                                type="button"
                                className="mr-2 px-4 py-2 rounded transition-all"
                            >
                                Cancel
                            </button>
                        </DialogClose>
                        <DialogClose>
                            <button
                                type="submit"
                                className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                            >
                                Create Ticket
                            </button>
                        </DialogClose>
                    </div>
                </form>
            )}
        </div>
    );
};

export default EditTicketModal;