import React, { useState, useEffect } from 'react';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Switch } from './ui/switch';
import { Label } from './ui/label';
import { Card, CardContent, CardHeader, CardTitle, CardDescription } from './ui/card';
import { useForm } from 'react-hook-form';
import { supabase } from '../supabaseClient';
import backend from '../api/axios';

const SettingsPage = () => {
  const { register, handleSubmit, setValue } = useForm();
  const [loading, setLoading] = useState(false);
  const [notificationSettings, setNotificationSettings] = useState({
    emailNotifications: true,
    smsNotifications: false,
  });

  useEffect(() => {
    // Fetch the user's settings from Supabase or your backend
    const fetchUserSettings = async () => {
      const { data, error } = await supabase.auth.getUser();

      if (data) {
        // Set the initial form values with user data
        setValue('email', data.user.email);
        setValue('username', data.user.username);
        // Fetch additional user settings from your backend if needed
      } else {
        console.error('Error fetching user settings:', error);
      }
    };

    fetchUserSettings();
  }, [setValue]);

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      // Update user profile in Supabase or your backend
      const { error } = await supabase.auth.updateUser({
        email: data.email,
        username: data.username,
      });

      if (error) {
        console.error('Error updating profile:', error);
        return;
      }

      alert('Profile updated successfully!');
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePasswordChange = async (data) => {
    setLoading(true);

    try {
      // Update user password in Supabase or your backend
      const { error } = await supabase.auth.updateUser({
        password: data.newPassword,
      });

      if (error) {
        console.error('Error updating password:', error);
        return;
      }

      alert('Password updated successfully!');
    } catch (error) {
      console.error('Error updating password:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleNotificationChange = async (setting) => {
    setNotificationSettings((prev) => ({
      ...prev,
      [setting]: !prev[setting],
    }));

    try {
      // Update notification settings in your backend
      await backend.post('/update-notifications', notificationSettings);
      alert('Notification settings updated successfully!');
    } catch (error) {
      console.error('Error updating notification settings:', error);
    }
  };

  return (
    <div className="p-4 max-w-xl mx-auto">
      <h1 className="text-3xl font-bold mb-6">Settings</h1>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Profile</CardTitle>
          <CardDescription>Update your profile information</CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mb-4">
              <Label htmlFor="email">Email</Label>
              <Input type="email" id="email" {...register('email')} disabled />
            </div>
            <div className="mb-4">
              <Label htmlFor="username">Username</Label>
              <Input type="text" id="username" {...register('username')} />
            </div>
            <Button type="submit" disabled={loading}>
              {loading ? 'Saving...' : 'Save Changes'}
            </Button>
          </form>
        </CardContent>
      </Card>

      <Card className="mb-6">
        <CardHeader>
          <CardTitle>Change Password</CardTitle>
          <CardDescription>Update your password</CardDescription>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit(handlePasswordChange)}>
            <div className="mb-4">
              <Label htmlFor="newPassword">New Password</Label>
              <Input type="password" id="newPassword" {...register('newPassword')} />
            </div>
            <Button type="submit" disabled={loading}>
              {loading ? 'Updating...' : 'Update Password'}
            </Button>
          </form>
        </CardContent>
      </Card>

      <Card>
        <CardHeader>
          <CardTitle>Notifications</CardTitle>
          <CardDescription>Manage your notification preferences</CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex items-center justify-between mb-4">
            <Label>Email Notifications</Label>
            <Switch
              checked={notificationSettings.emailNotifications}
              onCheckedChange={() => handleNotificationChange('emailNotifications')}
            />
          </div>
          <div className="flex items-center justify-between">
            <Label>SMS Notifications</Label>
            <Switch
              checked={notificationSettings.smsNotifications}
              onCheckedChange={() => handleNotificationChange('smsNotifications')}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SettingsPage;
