import React, { useState, memo } from 'react';
import backend from '../api/axios';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Label } from './ui/label';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import ReactMarkdown from 'react-markdown';
import PDFViewer from './PDFViewer';

const VerifyGRN = () => {
  const [poId, setPoId] = useState('');
  const [file, setFile] = useState(null);
  const [verificationResult, setVerificationResult] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [boundingBoxes, setBoundingBoxes] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!poId || !file) {
      alert('Please enter a PO ID and upload a file');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('po_number', poId);
    formData.append('file', file);
    console.log(poId);
    console.log(file);

    try {
      const response = await backend.post('/verify-po', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      console.log(response.data);

      setVerificationResult(response.data.verification_result);
      setPdfUrl(URL.createObjectURL(file));
      setBoundingBoxes(response.data.bounding_boxes);
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during verification');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      <Card className="bg-white dark:bg-gray-800 text-gray-900 dark:text-white">
        <CardHeader>
          <CardTitle className="text-2xl">Verify Goods Receipt Note</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4 mb-4">
            <div className="space-y-2">
              <Label htmlFor="poId" className="text-gray-900 dark:text-white">PO ID</Label>
              <Input
                id="poId"
                value={poId}
                onChange={(e) => setPoId(e.target.value)}
                required
                className="bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600"
              />
            </div>
            <div className="space-y-2">
              <Label htmlFor="file" className="text-gray-900 dark:text-white">Upload GRN (PDF)</Label>
              <Input
                id="file"
                type="file"
                accept=".pdf"
                onChange={(e) => setFile(e.target.files[0])}
                required
                className="bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600"
              />
            </div>
            <Button 
              type="submit" 
              disabled={isLoading}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              {isLoading ? 'Verifying...' : 'Verify GRN'}
            </Button>
          </form>
          <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
            <div className="w-full lg:w-1/2 h-[600px] border-2 border-gray-300 dark:border-gray-600 rounded-lg overflow-hidden">
              {pdfUrl ? (
                <PDFViewer pdfUrl={pdfUrl} boundingBoxes={boundingBoxes} />
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400">
                  No PDF uploaded
                </div>
              )}
            </div>
            <div className="w-full lg:w-1/2 h-[600px]">
              {verificationResult ? (
                <div className="h-full flex flex-col">
                  <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">Verification Result:</h3>
                  <div className="bg-gray-100 dark:bg-gray-800 p-4 rounded-md text-gray-900 dark:text-white flex-grow overflow-auto">
                    <div className="space-y-2">
                      {[
                        { label: "PO ID", value: verificationResult.po_id },
                        { label: "Supplier", value: verificationResult.supplier },
                        { label: "Person Assigned", value: verificationResult.person_assigned },
                        { label: "Status", value: verificationResult.status },
                        { label: "Quantity", value: verificationResult.quantity },
                        { label: "Net Price", value: verificationResult.net_price },
                        { label: "Inco Terms", value: verificationResult.inco_terms },
                        { label: "Part Number", value: verificationResult.part_number },
                        { label: "Unit", value: verificationResult.unit },
                        { label: "Type", value: verificationResult.type }
                      ].map(({ label, value }) => (
                        <div key={label} className="bg-white dark:bg-gray-700 p-3 rounded-md shadow">
                          <span className="font-semibold">{label}:</span> {value}
                        </div>
                      ))}
                    </div>
                    <div className="mt-4 bg-white dark:bg-gray-700 p-3 rounded-md shadow">
                      <span className="font-semibold">Conclusion:</span>
                      <ReactMarkdown className="mt-2">{verificationResult.conclusion}</ReactMarkdown>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="h-full flex items-center justify-center bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400 rounded-lg">
                  No verification result yet
                </div>
              )}
            </div>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default VerifyGRN;