import React, { useEffect, useState, useRef } from 'react';
import backend from '../api/axios';
import EditTicketModal from './EditTicketModal';
import ReactMarkdown from 'react-markdown';
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from './ui/select';
import { Button } from './ui/button';
import { AlarmClockOff, Archive, ArchiveX, ChevronsLeft, CornerUpLeft, CornerUpRight, EllipsisVertical, File, Inbox, Plus, Send, Trash2 } from 'lucide-react';
import { Tabs, TabsList, TabsTrigger } from './ui/tabs';
import { Input } from './ui/input';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from './ui/command';
import { Badge } from './ui/badge';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from './ui/tooltip';
import { Avatar, AvatarFallback } from './ui/avatar';
import { Textarea } from './ui/textarea';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Dialog, DialogContent, DialogTrigger } from './ui/dialog';
import { PopoverClose } from '@radix-ui/react-popover';
import { useDarkMode } from './new/DarkContext';
import { cn } from './ui/cn';

const ToolCard = ({ content, className, children }) => {
    return (
        <TooltipProvider>
            <Tooltip>
                <TooltipTrigger className={className}>{children}</TooltipTrigger>
                <TooltipContent className="h-7 bg-black text-gray-200 text-xs border-none">
                    {content}
                </TooltipContent>
            </Tooltip>
        </TooltipProvider>
    )
}

const Emails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [searchResults, setSearchResults] = useState([]);
    const [description, setDescription] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [isCreatingTicket, setIsCreatingTicket] = useState(false);
    const [editingEmail, setEditingEmail] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const resultsPerPage = 10;

    const [emails, setEmails] = useState([])
    const { dark } = useDarkMode();

    useEffect(() => {
        const get_emails = async () => {
            setIsLoading(true);
            try {
                const response = await backend.get('/email-threads')
                setEmails(response.data)
            } catch (error) {
                console.error('Error fetching emails:', error);
            } finally {
                setIsLoading(false);
            }
        }

        get_emails()
    }, [])

    const handleCreateTicketClick = (email) => {
        setEditingEmail(email);
    };

    const handleCreateTicket = async (ticketData) => {
        setIsCreatingTicket(true);
        try {
            const response = await backend.post('/create-ticket', ticketData);
            console.log('Ticket created:', response.data);
            alert('Ticket created successfully!');
            setEditingEmail(null);
        } catch (error) {
            console.error('Error creating ticket:', error);
            alert('Failed to create ticket. Please try again.');
        } finally {
            setIsCreatingTicket(false);
        }
    };

    const totalPages = Math.ceil(emails.length / resultsPerPage);
    const startIndex = (currentPage - 1) * resultsPerPage;
    const endIndex = startIndex + resultsPerPage;
    const currentEmails = emails.slice(startIndex, endIndex);

    const [selectedEmail, setSelectedEmail] = useState(null)

    const crop = (str, amt) => {
        if (str.length > amt) {
            return str.slice(0, amt) + "..."
        }

        return str
    }

    const handleSearch = async (e) => {
        e.preventDefault();
        if (!searchQuery.trim()) return;

        setIsLoading(true);
        try {
            const response = await backend.post('/email-search', { query: searchQuery });
            setSearchResults(response.data.results);
            setDescription(response.data.description);
            setEmails(response.data.results.map(result => [result.email]));
            setCurrentPage(1);
        } catch (error) {
            console.error('Error searching emails:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={cn(
            "p-8",
            dark ? "bg-slate-900 text-gray-200" : "bg-white text-gray-800"
        )}>
            <div className={cn(
                "grid grid-cols-8 bg-slate-800 border border-slate-600 rounded-lg max-h-[620px] h-[620px]",
                dark ? "bg-slate-800 border-slate-600" : "bg-white border-gray-200"
            )}>
                <div className="col-span-8">
                    <div className={cn(
                        "flex px-2 h-[52px] items-center justify-between border-b",
                        dark ? "border-slate-600" : "border-gray-200"
                    )}>
                        <form onSubmit={handleSearch} className={cn(
                            "w-full flex items-center",
                            dark ? "bg-slate-800" : "bg-white"
                        )}>
                            <input 
                                type="text"
                                className={cn(
                                    "h-8 w-full rounded-lg border text-gray-200 px-3",
                                    dark ? "bg-slate-700 border-slate-600" : "bg-gray-100 border-gray-200"
                                )}
                                placeholder="Search emails..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </form>
                        <Tabs defaultValue="All mail" className="ml-2">
                            <TabsList className={cn(
                                "h-8 border rounded-lg",
                                dark ? "bg-slate-700 border-slate-600" : "bg-gray-100 border-gray-200"
                            )}>
                                <TabsTrigger value="All mail" className={cn(
                                    "h-6 data-[state=active]:bg-gray-200 data-[state=active]:text-slate-800",
                                    dark ? "text-gray-200" : "text-gray-800"
                                )}>
                                    All mail
                                </TabsTrigger>
                                <TabsTrigger value="Unread" className={cn(
                                    "h-6 data-[state=active]:bg-gray-200 data-[state=active]:text-slate-800",
                                    dark ? "text-gray-200" : "text-gray-800"
                                )}>
                                    Unread
                                </TabsTrigger>
                            </TabsList>
                        </Tabs>
                    </div>
                    <div className="grid grid-cols-8 h-[568px]">
                        <div className={cn(
                            "col-span-4 border-r overflow-hidden flex flex-col",
                            dark ? "border-slate-600 bg-slate-800" : "border-gray-200 bg-white"
                        )}>
                            {description && (
                                <div className={cn(
                                    "text-gray-200 text-xs p-2 m-2 bg-slate-700 border rounded-lg",
                                    dark ? "border-slate-600" : "border-gray-200"
                                )}>
                                    {description}
                                </div>
                            )}
                            <div className={cn(
                                "p-4 flex-grow overflow-y-auto",
                                dark ? "bg-slate-800" : "bg-white"
                            )}>
                                {isLoading ? (
                                    <div className={cn(
                                        "text-gray-200",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )}>Loading...</div>
                                ) : currentEmails.length > 0 ? (
                                    currentEmails.map((email) => (
                                        <div key={email[0].id} className={cn(
                                            "mb-2 p-0 border overflow-hidden",
                                            dark ? "border-slate-600" : "border-gray-200"
                                        )}>
                                            <div
                                                className={cn(
                                                    "group flex flex-col w-full h-full p-3 gap-4 transition-all cursor-pointer",
                                                    dark ? "bg-slate-700 hover:bg-slate-600" : "bg-gray-100 hover:bg-gray-200"
                                                )}
                                                onClick={() => {
                                                    setSelectedEmail(email[0])
                                                    handleCreateTicketClick(email[0])
                                                }}
                                            >
                                                <span className={cn(
                                                    "flex flex-col gap-1 justify-between",
                                                    dark ? "text-gray-200" : "text-gray-800"
                                                )}>
                                                    <span className="font-semibold">{email[0].sender_name}</span>
                                                    <span className="text-xs">{crop(email[0].subject, 24)}</span>
                                                </span>
                                                <span className={cn(
                                                    "text-xs group-hover:text-gray-400 transition-all",
                                                    dark ? "text-gray-500" : "text-gray-600"
                                                )}>
                                                    {crop(email[0].content, 64)}
                                                </span>
                                                {email[0].id % 2 == 0 ?
                                                    <div className="flex gap-4">
                                                        <Badge>
                                                            work
                                                        </Badge>
                                                        <Badge variant={"outline"} className={cn(
                                                            "border-none bg-slate-700 hover:bg-slate-700 group-hover:bg-slate-600 text-gray-200",
                                                            dark ? "bg-slate-700 hover:bg-slate-700 group-hover:bg-slate-600 text-gray-200" : "bg-gray-100 hover:bg-gray-200 group-hover:bg-gray-300 text-gray-800"
                                                        )}>
                                                            important
                                                        </Badge>
                                                    </div> :
                                                    <div className="flex gap-4">
                                                        <Badge className={cn(
                                                            "border-none bg-red-700/50 hover:bg-red-700/50",
                                                            dark ? "bg-red-700/50 hover:bg-red-700/50" : "bg-red-200 hover:bg-red-300"
                                                        )}>
                                                            meeting
                                                        </Badge>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className={cn(
                                        "text-gray-200",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )}>No emails found</div>
                                )}
                            </div>
                            {totalPages > 1 && (
                                <div className={cn(
                                    "flex justify-between items-center p-2 border-t",
                                    dark ? "bg-slate-700 border-slate-600" : "bg-gray-100 border-gray-200"
                                )}>
                                    <Button
                                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                                        disabled={currentPage === 1}
                                        variant="ghost"
                                        size="sm"
                                        className={cn(
                                            "text-gray-200 hover:text-gray-400",
                                            dark ? "text-gray-200 hover:text-gray-400" : "text-gray-800 hover:text-gray-600"
                                        )}
                                    >
                                        Previous
                                    </Button>
                                    <span className={cn(
                                        "text-gray-200 text-sm",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )}>
                                        Page {currentPage} of {totalPages}
                                    </span>
                                    <Button
                                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                                        disabled={currentPage === totalPages}
                                        variant="ghost"
                                        size="sm"
                                        className={cn(
                                            "text-gray-200 hover:text-gray-400",
                                            dark ? "text-gray-200 hover:text-gray-400" : "text-gray-800 hover:text-gray-600"
                                        )}
                                    >
                                        Next
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col col-span-4 h-full">
                            <div className={cn(
                                "flex px-2 h-[52px] items-center justify-between border-b",
                                dark ? "border-slate-600 bg-slate-800" : "border-gray-200 bg-white"
                            )}>
                                <div className="flex items-center gap-2">
                                    <ToolCard className={cn(
                                        "text-gray-200",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )} content={"Archive"}>
                                        <Button variant="ghost" size="sm">
                                            <Archive className="size-4" />
                                        </Button>
                                    </ToolCard>
                                    <ToolCard className={cn(
                                        "text-gray-200",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )} content={"Move to Junk"}>
                                        <Button variant="ghost" size="sm">
                                            <ArchiveX className="size-4" />
                                        </Button>
                                    </ToolCard>
                                    <ToolCard className={cn(
                                        "text-gray-200",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )} content={"Move to Trash"}>
                                        <Button variant="ghost" size="sm">
                                            <Trash2 className="size-4" />
                                        </Button>
                                    </ToolCard>
                                    <div className={cn(
                                        "h-[26px] w-[1px]",
                                        dark ? "bg-gray-400" : "bg-gray-200"
                                    )}></div>
                                    <ToolCard className={cn(
                                        "text-gray-200",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )} content={"Snooze"}>
                                        <Button variant="ghost" size="sm">
                                            <AlarmClockOff className="size-4" />
                                        </Button>
                                    </ToolCard>
                                </div>
                                <div className="flex items-center gap-2">
                                    <ToolCard className={cn(
                                        "text-gray-200",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )} content={"Reply"}>
                                        <Button variant="ghost" size="sm">
                                            <CornerUpLeft className="size-4" />
                                        </Button>
                                    </ToolCard>
                                    <ToolCard className={cn(
                                        "text-gray-200",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )} content={"Reply All"}>
                                        <Button variant="ghost" size="sm">
                                            <ChevronsLeft className="size-4" />
                                        </Button>
                                    </ToolCard>
                                    <ToolCard className={cn(
                                        "text-gray-200",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )} content={"Forward"}>
                                        <Button variant="ghost" size="sm">
                                            <CornerUpRight className="size-4" />
                                        </Button>
                                    </ToolCard>
                                    <div className={cn(
                                        "h-[26px] w-[1px]",
                                        dark ? "bg-gray-400" : "bg-gray-200"
                                    )}></div>
                                    <ToolCard className={cn(
                                        "text-gray-200",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )} content={"More"}>
                                        <Dialog>
                                            <Popover>
                                                <PopoverTrigger asChild>
                                                    <Button variant="ghost" size="sm">
                                                        <EllipsisVertical className="size-4" />
                                                    </Button>
                                                </PopoverTrigger>
                                                <PopoverContent align="end" className={cn(
                                                    "w-[135px] p-1",
                                                    dark ? "bg-slate-700 border-slate-600" : "bg-white border-gray-200"
                                                )}>
                                                    <PopoverClose>
                                                        <DialogTrigger>
                                                            <Button onClick={() => handleCreateTicketClick(selectedEmail)} variant="ghost" className={cn(
                                                                "flex gap-2 items-center justify-center h-8 w-full hover:bg-slate-600 text-gray-300 hover:text-gray-300",
                                                                dark ? "hover:bg-slate-600 text-gray-200 hover:text-gray-200" : "hover:bg-gray-200 text-gray-800 hover:text-gray-600"
                                                            )}>
                                                                <Plus className="size-4" />
                                                                Create PO
                                                            </Button>
                                                        </DialogTrigger>
                                                    </PopoverClose>
                                                </PopoverContent>
                                            </Popover>
                                            <DialogContent className={cn(
                                                "bg-slate-700 border-slate-600",
                                                dark ? "bg-slate-700 border-slate-600" : "bg-white border-gray-200"
                                            )}>
                                                <EditTicketModal
                                                    email={editingEmail}
                                                    setEmail={setEditingEmail}
                                                    onCreateTicket={handleCreateTicket}
                                                />
                                            </DialogContent>
                                        </Dialog>
                                    </ToolCard>
                                </div>
                            </div>
                            <div className={cn(
                                "flex flex-col text-gray-200 h-full",
                                dark ? "bg-slate-800" : "bg-white"
                            )}>
                                {selectedEmail !== null ? (
                                    <>
                                        <div className={cn(
                                            "flex gap-4 border-b p-4",
                                            dark ? "border-slate-600" : "border-gray-200"
                                        )}>
                                            <div className="flex items-center">
                                                <Avatar>
                                                    <AvatarFallback className={cn(
                                                        "bg-black border-2",
                                                        dark ? "border-slate-600" : "border-gray-200"
                                                    )}>{selectedEmail.sender_name[0].toUpperCase()}</AvatarFallback>
                                                </Avatar>
                                            </div>
                                            <div className="flex flex-col gap-1">
                                                <div className="flex items-center justify-between">
                                                    <span className="text-sm font-semibold">{selectedEmail.sender_name}</span>
                                                    <span>{selectedEmail.createdAt}</span>
                                                </div>
                                                <span className="text-xs">{crop(selectedEmail.subject, 100)}</span>
                                                <div>
                                                    <span className="text-xs font-semibold mr-1">Reply-To:</span>
                                                    <span className="text-xs">{selectedEmail.sender_email}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col flex-grow overflow-hidden">
                                            <div className={cn(
                                                "flex-grow overflow-auto p-4 text-xs whitespace-pre-wrap",
                                                dark ? "bg-slate-800 text-gray-200 border-slate-600" : "bg-white text-gray-800 border-gray-200"
                                            )}>
                                                {selectedEmail.content}
                                            </div>
                                            <div className={cn(
                                                "p-4",
                                                dark ? "bg-slate-800" : "bg-white"
                                            )}>
                                                <div className="flex items-center gap-2">
                                                    <Textarea 
                                                        placeholder={`Reply to ${selectedEmail.sender_name}`} 
                                                        className={cn(
                                                            "bg-slate-700 border-slate-600 text-gray-200 outline-none h-24 resize-none flex-grow",
                                                            dark ? "bg-slate-700 border-slate-600 text-gray-200" : "bg-gray-100 border-gray-200 text-gray-800"
                                                        )}
                                                    />
                                                    <Button variant="default" className={cn(
                                                        "h-24 w-20 rounded-md text-white",
                                                        dark ? "bg-blue-800 hover:bg-blue-900" : "bg-blue-500 hover:bg-blue-600"
                                                    )}>
                                                        Send
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className={cn(
                                        "flex w-full h-full items-center justify-center font-semibold",
                                        dark ? "text-gray-200" : "text-gray-800"
                                    )}>
                                        No Email Selected
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

/*
<div className="bg-gray-100 min-h-screen p-8">
      <div className="bg-white shadow-xl rounded-lg p-8 w-full max-w-[1600px] mx-auto">
        <h1 className="text-3xl font-bold text-gray-800 mb-6">Email Search</h1>
        <form onSubmit={handleSearch} className="mb-8">
          <div className="flex">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search emails"
              className="flex-grow p-3 border rounded-l-md text-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <button type="submit" className="px-6 py-3 bg-blue-500 text-white rounded-r-md hover:bg-blue-600 transition duration-300 text-lg">
              Search
            </button>
          </div>
        </form>

        {isLoading ? (
          <p className="text-gray-600 italic text-xl">Loading...</p>
        ) : searchResults.length > 0 ? (
          <div>
            {description && (
              <div className="mb-8 p-6 bg-gradient-to-r from-blue-50 to-indigo-50 rounded-lg shadow-md">
                <h2 className="text-2xl font-semibold mb-4 text-indigo-700">Search Results Summary</h2>
                <div className="prose max-w-none text-lg">
                  <ReactMarkdown>{description}</ReactMarkdown>
                </div>
              </div>
            )}
            {displayedResults.map((result, index) => (
              <div key={index} className="mb-8 p-6 bg-white border rounded-lg shadow-md hover:shadow-lg transition duration-300">
                <div className="grid grid-cols-2 gap-4 mb-4">
                  <p className="text-lg"><span className="font-semibold">Thread ID:</span> {result.email.thread_id}</p>
                  <p className="text-lg"><span className="font-semibold">Email ID:</span> {result.email.id}</p>
                  <p className="text-lg"><span className="font-semibold">From:</span> {result.email.sender_name} ({result.email.sender_email})</p>
                  <p className="text-lg"><span className="font-semibold">To:</span> {result.email.receiver_name} ({result.email.receiver_email})</p>
                  <p className="text-lg"><span className="font-semibold">Subject:</span> {result.email.subject}</p>
                  <p className="text-lg"><span className="font-semibold">Date:</span> {new Date(result.email.created_at).toLocaleString()}</p>
                </div>
                <div className="mb-4">
                  <p className="font-semibold text-lg mb-2">Body:</p>
                  <div className="p-4 bg-gray-50 rounded-md whitespace-pre-wrap text-lg">{result.email.content}</div>
                </div>
                <div className="flex justify-end">
                  <button
                    onClick={() => handleCreateTicketClick(result.email)}
                    className="px-6 py-3 bg-green-500 text-white rounded-md hover:bg-green-600 transition duration-300 text-lg"
                  >
                    Create Ticket
                  </button>
                </div>
              </div>
            ))}
            {totalPages > 1 && (
              <div className="flex justify-center mt-8">
                {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                  <button
                    key={page}
                    onClick={() => setCurrentPage(page)}
                    className={`mx-1 px-4 py-2 rounded-md transition duration-300 text-lg ${
                      currentPage === page ? 'bg-blue-500 text-white' : 'bg-gray-200 hover:bg-gray-300'
                    }`}
                  >
                    {page}
                  </button>
                ))}
              </div>
            )}
          </div>
        ) : (
          <p className="text-gray-600 italic text-xl">No results to display. Please perform a search.</p>
        )}

        {editingEmail && (
          <EditTicketModal
            isOpen={!!editingEmail}
            onRequestClose={() => setEditingEmail(null)}
            email={editingEmail}
            onCreateTicket={handleCreateTicket}
          />
        )}

        {isCreatingTicket && (
          <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg shadow-md">
              <p className="text-xl font-semibold">Creating ticket...</p>
            </div>
          </div>
        )}
      </div>
    </div>
*/

export default Emails;