// src/components/Dashboard.js

import React from 'react';
import TitleBar from './TitleBar';
import ItemDetail from './ItemDetail';
import Recommendations from './Recommendations';
import Reporting from './Reporting';
import Customer from './Customer';
import Opportunity from './Opportunity';
import '../assets/styles/table.css';
import '../assets/styles/modal.css';
import '../assets/styles/aside.css';
import '../assets/styles/button.css';
import '../assets/styles/card.css';
import '../assets/styles/hero.css';
import '../assets/styles/icon.css';
import '../assets/styles/image.css';
import '../assets/styles/notification.css';
import '../assets/styles/progress.css';
import '../assets/styles/app.css';

const Dashboard = ({ searchQuery }) => {
  return (
    <div>
      <TitleBar pageName="Dashboard" />
      <section className="is-hero-bar">
        <div className="flex flex-col md:flex-row items-center justify-between space-y-6 md:space-y-0">
          <h1 className="title">Dashboard</h1>
        </div>
      </section>

      <section className="section main-section">
        <div className="grid gap-6 grid-cols-1 md:grid-cols-3 mb-6">
          <div className="card">
            <div className="card-content">
              <div className="flex items-center justify-between">
                <div className="widget-label">
                  <h3>Clients</h3>
                  <h1>512</h1>
                </div>
                <span className="icon widget-icon text-green-500"><i className="mdi mdi-account-multiple mdi-48px"></i></span>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <div className="flex items-center justify-between">
                <div className="widget-label">
                  <h3>Sales</h3>
                  <h1>$7,770</h1>
                </div>
                <span className="icon widget-icon text-blue-500"><i className="mdi mdi-cart-outline mdi-48px"></i></span>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-content">
              <div className="flex items-center justify-between">
                <div className="widget-label">
                  <h3>Reporting</h3>
                  <h1>256%</h1>
                </div>
                <span className="icon widget-icon text-red-500"><i className="mdi mdi-finance mdi-48px"></i></span>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section main-section">
  <div className="grid gap-6 grid-cols-1 lg:grid-cols-3 mb-6">
    <div className="col-span-3">
      <div className="card">
        <div className="card-content">
          <Reporting searchQuery={searchQuery} />
        </div>
      </div>
    </div>
    <div className="lg:col-span-1 space-y-6">
      <div className="card">
        <div className="card-content">
          <Customer searchQuery={searchQuery} />
        </div>
      </div>
    </div>
    <div className="lg:col-span-1 space-y-6">
      <div className="card">
        <div className="card-content">
          <Opportunity searchQuery={searchQuery} />
        </div>
      </div>
    </div>
    <div className="lg:col-span-1 space-y-6">
      <div className="card">
        <div className="card-content">
          <ItemDetail searchQuery={searchQuery} />
        </div>
      </div>
    </div>
  </div>
</section>



      <section className="section main-section">
        <div className="notification blue">
          <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0">
            <div>
              <span className="icon"><i className="mdi mdi-buffer"></i></span>
              <b>Responsive table</b>
            </div>
            <button type="button" className="button small textual --jb-notification-dismiss">Dismiss</button>
          </div>
        </div>

        <div className="card has-table">
          <header className="card-header">
            <p className="card-header-title">
              <span className="icon"><i className="mdi mdi-account-multiple"></i></span>
              Clients
            </p>
            <a href="#" className="card-header-icon">
              <span className="icon"><i className="mdi mdi-reload"></i></span>
            </a>
          </header>
          <div className="card-content">
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Company</th>
                  <th>City</th>
                  <th>Progress</th>
                  <th>Created</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="image-cell">
                    <div className="image">
                      <img src="https://avatars.dicebear.com/v2/initials/rebecca-bauch.svg" className="rounded-full" alt="Rebecca Bauch"/>
                    </div>
                  </td>
                  <td data-label="Name">Rebecca Bauch</td>
                  <td data-label="Company">Daugherty-Daniel</td>
                  <td data-label="City">South Cory</td>
                  <td data-label="Progress" className="progress-cell">
                    <progress max="100" value="79">79</progress>
                  </td>
                  <td data-label="Created">
                    <small className="text-gray-500" title="Oct 25, 2021">Oct 25, 2021</small>
                  </td>
                  <td className="actions-cell">
                    <div className="buttons right nowrap">
                      <button className="button small blue --jb-modal"  data-target="sample-modal-2" type="button">
                        <span className="icon"><i className="mdi mdi-eye"></i></span>
                      </button>
                      <button className="button small red --jb-modal" data-target="sample-modal" type="button">
                        <span className="icon"><i className="mdi mdi-trash-can"></i></span>
                      </button>
                    </div>
                  </td>
                </tr>
                {/* Add more rows here */}
              </tbody>
            </table>
            <div className="table-pagination">
              <div className="flex items-center justify-between">
                <div className="buttons">
                  <button type="button" className="button active">1</button>
                  <button type="button" class="button">2</button>
                  <button type="button" className="button">3</button>
                </div>
                <small>Page 1 of 3</small>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
