import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Command, CommandDialog, CommandInput, CommandList, CommandGroup, CommandItem, CommandEmpty } from './ui/command';
import { FileSpreadsheet, Plus } from 'lucide-react';
import backend from '../api/axios';
import { cn } from './ui/cn';
import { useDarkMode } from './new/DarkContext';

const InventoryManager = () => {
  const { dark } = useDarkMode();
  const [inventories, setInventories] = useState([]);
  const [selectedInventory, setSelectedInventory] = useState(null);
  const [file, setFile] = useState(null);
  const [isCommandOpen, setIsCommandOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newInventoryName, setNewInventoryName] = useState('');

  useEffect(() => {
    // Fetch the list of inventories from the backend
    const fetchInventories = async () => {
      setIsLoading(true);
      try {
        const response = await backend.get('/inventory-list');
        setInventories(response.data);
      } catch (error) {
        console.error('Error fetching inventories:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchInventories();
  }, []);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && selectedFile.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      setFile(selectedFile);
    } else {
      alert("Please upload a valid Excel file.");
    }
  };

  const handleUpload = async () => {
    if (!file || !selectedInventory) return;

    setIsLoading(true);
    try {
      // Send the selected file and inventory ID to the backend for processing
      const formData = new FormData();
      formData.append('file', file);
      formData.append('inventoryId', selectedInventory.id);

      const response = await backend.post('/process-inventory', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.data.success) {
        alert('Inventory updated successfully!');
        setIsCommandOpen(false);
        setFile(null);
      } else {
        alert('Error processing file: ' + response.data.error);
      }
    } catch (error) {
      console.error('Error processing file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateInventory = async () => {
    if (!newInventoryName.trim()) return;

    setIsLoading(true);
    try {
      const response = await backend.post('/create-inventory', { name: newInventoryName });
      if (response.data.success) {
        setInventories([...inventories, response.data.inventory]);
        setNewInventoryName('');
      } else {
        alert('Error creating inventory: ' + response.data.error);
      }
    } catch (error) {
      console.error('Error creating inventory:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={cn(
      "flex flex-col p-4",
      dark ? "bg-slate-900 text-gray-200" : "bg-white text-gray-800"
  )}>
      <h1 className="text-2xl font-bold mb-4">Inventory Manager</h1>
      
      {/* New inventory creation form */}
      <div className="mb-4 flex items-center">
        <input
          type="text"
          value={newInventoryName}
          onChange={(e) => setNewInventoryName(e.target.value)}
          placeholder="New Inventory Name"
          className="border p-2 mr-2 flex-grow"
        />
        <Button
          onClick={handleCreateInventory}
          disabled={isLoading || !newInventoryName.trim()}
          className="flex items-center"
        >
          <Plus className="mr-2" size={16} />
          Create
        </Button>
      </div>

      <div className="flex flex-1">
        <div className="w-1/4 border-r pr-4">
          {isLoading ? (
            <p>Loading...</p>
          ) : (
            inventories.map((inventory) => (
              <Button
                key={inventory.id}
                variant="outline"
                className="w-full justify-start text-left mb-2"
                onClick={() => {
                  setSelectedInventory(inventory);
                  setIsCommandOpen(true);
                }}
              >
                {inventory.name}
              </Button>
            ))
          )}
        </div>

        <div className="w-3/4 pl-4">
          {selectedInventory ? (
            <div>
              <h2 className="text-xl font-semibold mb-2">{selectedInventory.name}</h2>
              <p className="mb-4">Last updated: {new Date(selectedInventory.updated_at).toLocaleDateString()}</p>

              <Command open={isCommandOpen} onOpenChange={setIsCommandOpen}>
                <CommandDialog>
                  <CommandInput placeholder="Upload and Update Inventory" />
                  <CommandList>
                    <CommandGroup>
                      <CommandItem>
                        <input type="file" onChange={handleFileChange} className="file-input mb-2" />
                      </CommandItem>
                    </CommandGroup>
                    <CommandEmpty>No results found.</CommandEmpty>
                  </CommandList>
                </CommandDialog>
              </Command>

              <Button
                onClick={handleUpload}
                className="mt-4 w-full"
                disabled={!file || isLoading}
                leftIcon={<FileSpreadsheet />}
              >
                {isLoading ? 'Uploading...' : 'Upload and Update Inventory'}
              </Button>
            </div>
          ) : (
            <p>Select an inventory to view details and upload new files.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default InventoryManager;