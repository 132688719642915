import React, { useEffect, useState, useRef } from 'react';
import backend from '../api/axios';
import { useParams } from 'react-router-dom';
import TitleBar from './TitleBar';
import { Input } from './ui/input';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Button } from './ui/button';
import { CirclePlus, GitPullRequest, GitPullRequestDraft, GitPullRequestClosed, Ellipsis, Plus, ClipboardIcon } from 'lucide-react';
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from './ui/command';
import { Checkbox } from './ui/checkbox';
import { Pagination, PaginationContent, PaginationItem, PaginationPrevious, PaginationEllipsis, PaginationNext, PaginationLink } from './ui/pagination';
import { Dialog, DialogClose, DialogContent, DialogTrigger } from './ui/dialog';
import { useDarkMode } from './new/DarkContext';
import { cn } from './ui/cn';
import { TooltipProvider, Tooltip, TooltipTrigger, TooltipContent } from "./ui/tooltip"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PurchaseOrders = () => {
    const { id } = useParams();

    const [tickets, setTickets] = useState([]);
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [newPO, setNewPO] = useState({
        ticket_id: '',
        supplier: '',
        quantity: '',
        net_price: '',
        inco_terms: '',
        person_assigned: '',
        unit: '',
    });
    const [highlightedPo, setHighlightedPo] = useState(null);
    const refs = useRef({});

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewPO(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleCreatePO = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        Object.keys(newPO).forEach(key => {
            formData.append(key, newPO[key]);
        });
        try {
            await backend.post(`/pos`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setNewPO({
                ticket_id: '',
                supplier: '',
                quantity: '',
                net_price: '',
                inco_terms: '',
                person_assigned: '',
                unit: '',
            });
            setShowCreateForm(false);
            fetchPurchaseOrders();
            fetchOpenTickets(); // Refresh tickets to move the updated ticket to pending
        } catch (error) {
            console.error('Error creating purchase order:', error);
        }
    };

    const [openTag, setOpenTag] = useState(false)
    const [closedTag, setClosedTag] = useState(false)
    const [pendingTag, setPendingTag] = useState(false)

    const [purchaseOrders, setPurchaseOrders] = useState([]);
    const [query, setQuery] = useState("")

    const [page, setPage] = useState(1)

    const filteredPOs =
        query === ''
            ? purchaseOrders
            : purchaseOrders.filter((po) => {
                return Object.values(po).filter((val) => {
                    if (typeof val == "string") {
                        return val.toLowerCase().includes(query.toLowerCase())
                    }
                }).length > 0
            })

    const furtherFilteredPOs =
        !pendingTag && !closedTag
            ? filteredPOs
            : filteredPOs.filter((po) => {
                return (
                    closedTag && po.status === "completed" ||
                    pendingTag && po.status === "incomplete"
                )
            })

    useEffect(() => {
        fetchPurchaseOrders();
        fetchOpenTickets();
    }, []);


    const fetchPurchaseOrders = async () => {
        try {
            const response = await backend.get(`/pos`);
            setPurchaseOrders(response.data);
        } catch (error) {
            console.error('Error fetching purchase orders:', error);
        }
    };

    const fetchOpenTickets = async () => {
        try {
            const response = await backend.get(`/tickets`);
            setTickets(response.data.filter(ticket => ticket.status === 'open'));
        } catch (error) {
            console.error('Error fetching open tickets:', error);
        }
    };

    const handleCompletePO = async (po_id) => {
        try {
            await backend.post(`/complete_po/${po_id}`);
            fetchPurchaseOrders(); // Refresh purchase orders list
        } catch (error) {
            console.error('Error completing purchase order:', error);
        }
    };

    const { dark } = useDarkMode()

    const handleCopy = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert('Copied to clipboard');
        });
    };

    function CopyIcon(props) {
        return (
            <svg
                {...props}
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <rect width="14" height="14" x="8" y="8" rx="2" ry="2" />
                <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
            </svg>
        )
    }

    return (
        <div className="flex flex-col gap-4 p-8 bg-white dark:bg-slate-900 text-gray-800 dark:text-gray-200">
            <ToastContainer />
            <div className="flex justify-between">
                <div className="flex flex-1 items-center gap-2">
                    <Input placeholder="Filter POs..." className="h-8 w-[150px] lg:w-[250px] dark:bg-slate-800 dark:border-slate-600 dark:text-gray-200" onChange={(e) => setQuery(e.target.value)} />
                    <Popover>
                        <PopoverTrigger asChild>
                            <Button variant="outline" className="dark:text-gray-400 dark:hover:text-gray-200 flex items-center justify-center gap-2 border-dashed h-8 dark:bg-slate-800 dark:border-slate-600 dark:hover:bg-slate-600 dark:hover:border-slate-400">
                                <CirclePlus className="size-4" />
                                <span>Status</span>
                                {(openTag || closedTag || pendingTag) && (
                                    <>
                                        <div className="w-[1px] h-full bg-gray-800 dark:bg-slate-400" />
                                        {openTag && <div className="dark:bg-slate-700 px-1 py-0.5 rounded-sm"><GitPullRequest className="size-3" /></div>}
                                        {closedTag && <div className="dark:bg-slate-700 px-1 py-0.5 rounded-sm"><GitPullRequestClosed className="size-3" /></div>}
                                        {pendingTag && <div className="dark:bg-slate-700 px-1 py-0.5 rounded-sm"><GitPullRequestDraft className="size-3" /></div>}
                                    </>
                                )}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent align="start" sideOffset="1" className={cn(
                            "w-[200px] p-0",
                            dark && "border-none"
                        )}>
                            <Command className={cn(
                                dark && "bg-slate-800 border border-slate-600", 
                                "rounded-sm"
                            )}>
                                <CommandInput
                                    iconClassName={dark && "stroke-gray-200"}
                                    wrapperClassName={dark && "border-b-slate-600"}
                                    className={dark && "text-gray-200"}
                                    placeholder="Search tags..."
                                />
                                <CommandList>
                                    <CommandEmpty>
                                        <span className={dark && "text-gray-200"}>No results found</span>
                                    </CommandEmpty>
                                    <CommandGroup>
                                        <CommandItem disable className={dark && "aria-selected:bg-slate-700"}>
                                            <Checkbox id="open" 
                                                className={cn(
                                                    dark && "border-slate-500 data-[state=checked]:bg-slate-800", 
                                                    "mr-2"
                                                )} 
                                                checked={openTag} 
                                                onCheckedChange={setOpenTag} 
                                            />
                                            <label htmlFor="open" className={cn(
                                                "flex items-center gap-2 w-full",
                                                dark && "text-gray-200"
                                            )}>
                                                <GitPullRequest className="size-4" />
                                                Open
                                            </label>
                                        </CommandItem>
                                        <CommandItem disable className={dark && "aria-selected:bg-slate-700"}>
                                            <Checkbox id="closed" 
                                                className={cn(
                                                    dark && "border-slate-500 data-[state=checked]:bg-slate-800", 
                                                    "mr-2"
                                                )} 
                                                checked={closedTag} 
                                                onCheckedChange={setClosedTag} 
                                            />
                                            <label htmlFor="closed" className={cn(
                                                "flex items-center gap-2 w-full",
                                                dark && "text-gray-200"
                                            )}>
                                                <GitPullRequestClosed className="size-4" />
                                                Closed
                                            </label>
                                        </CommandItem>
                                        <CommandItem disable className={dark && "aria-selected:bg-slate-700"}>
                                            <Checkbox id="pending" 
                                                className={cn(
                                                    dark && "border-slate-500 data-[state=checked]:bg-slate-800", 
                                                    "mr-2"
                                                )} 
                                                checked={pendingTag} 
                                                onCheckedChange={setPendingTag} 
                                            />
                                            <label htmlFor="pending" className={cn(
                                                "flex items-center gap-2 w-full",
                                                dark && "text-gray-200"
                                            )}>
                                                <GitPullRequestDraft className="size-4" />
                                                Pending
                                            </label>
                                        </CommandItem>
                                    </CommandGroup>
                                </CommandList>
                            </Command>
                        </PopoverContent>
                    </Popover>
                </div>
                <Dialog>
                    <DialogTrigger asChild>
                        <Button variant="ghost" className="h-8 w-[150px] flex items-center gap-2 dark:text-gray-400">
                            <Plus className="size-4" />
                            Add PO
                        </Button>
                    </DialogTrigger>
                    <DialogContent className={dark && "bg-slate-700 border-slate-600"}>
                        <form onSubmit={handleCreatePO} className="flex flex-col gap-8">
                            <div className="flex flex-col gap-6">
                                <div className="flex flex-col gap-1">
                                    <label className={cn(
                                        "font-semibold text-gray-700",
                                        dark && "text-gray-400"
                                    )}>Open Ticket</label>
                                    <select
                                        name="ticket_id"
                                        value={newPO.ticket_id}
                                        onChange={handleInputChange}
                                        className={cn(
                                            "mt-1 p-2 text-sm text-gray-400 w-full bg-gray-100 border rounded appearance-none outline-none cursor-pointer",
                                            dark && "bg-slate-700 border-slate-600"
                                        )}
                                    >
                                        <option value="">Select Ticket</option>
                                        {tickets.map(ticket => (
                                            <option key={ticket.id} value={ticket.id}>
                                                {ticket.id}
                                                <TooltipProvider>
                                                    <Tooltip>
                                                        <TooltipTrigger asChild>
                                                            <Button variant="outline" onClick={() => handleCopy(ticket.id)}>
                                                                <ClipboardIcon className="ml-17 h-4 w-4" />
                                                            </Button>
                                                        </TooltipTrigger>
                                                        <TooltipContent>  
                                                            <p>Copy Ticket ID</p>
                                                        </TooltipContent>
                                                    </Tooltip>
                                                </TooltipProvider>
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <label className={cn(
                                        "font-semibold text-gray-700",
                                        dark && "text-gray-400"
                                    )}>Inco Term</label>
                                    <select
                                        name="inco_terms"
                                        value={newPO.inco_terms}
                                        onChange={handleInputChange}
                                        className={cn(
                                            "mt-1 p-2 text-sm text-gray-400 w-full bg-gray-100 border rounded appearance-none outline-none cursor-pointer",
                                            dark && "bg-slate-700 border-slate-600"
                                        )}
                                    >
                                        <option value="">Select INCO</option>
                                        <option value="EXW">EXW</option>
                                        <option value="FCA">FCA</option>
                                        <option value="CPT">CPT</option>
                                        <option value="CIP">CIP</option>
                                        <option value="DAP">DAP</option>
                                        <option value="DPU">DPU</option>
                                        <option value="DDP">DDP</option>
                                        <option value="FAS">FAS</option>
                                        <option value="FOB">FOB</option>
                                        <option value="CFR">CFR</option>
                                        <option value="CIF">CIF</option>
                                    </select>
                                </div>
                                <div className="flex flex-col gap-1">
                                    <label className={cn(
                                        "font-semibold text-gray-700",
                                        dark && "text-gray-400"
                                    )}>Units</label>
                                    <input
                                        type="text"
                                        name="unit"
                                        value={newPO.unit}
                                        onChange={handleInputChange}
                                        className={cn(
                                            "mt-1 p-2 text-sm text-gray-400 w-full bg-gray-100 border rounded appearance-none outline-none",
                                            dark && "bg-slate-700 border-slate-600"
                                        )}
                                        placeholder="Select Units"
                                    />
                                </div>
                            </div>
                            <DialogClose asChild>
                                <button type="submit" className={cn(
                                    "text-gray-800 bg-gray-100 hover:bg-gray-200 py-2 px-4 rounded",
                                    dark && "text-gray-200 bg-slate-600 hover:bg-slate-500"
                                )}>
                                    Create Purchase Order
                                </button>
                            </DialogClose>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>

            <div className="rounded-md shadow-md border dark:border-slate-600 overflow-hidden">
                <div className="w-full">
                    <table className="w-full caption-bottom text-sm dark:bg-slate-800 rounded-md">
                        <thead>
                            <tr className="h-11 border-b dark:border-b-slate-600 dark:text-gray-400 font-medium text-xs">
                                <th className="px-2 text-left align-middle">ID</th>
                                <th className="px-2 text-left align-middle">Supplier</th>
                                <th className="px-2 text-left align-middle">Inco Terms</th>
                                <th className="px-2 text-left align-middle">Quantity</th>
                                <th className="px-2 text-left align-middle">Price / Unit</th>
                                <th className="px-2 text-left align-middle">Unit</th>
                                <th className="px-2 text-left align-middle">Type</th>
                                <th className="px-2 text-left align-middle">Status</th>
                            </tr>
                        </thead>
                        <tbody className="dark:bg-slate-800 [&_tr:last-child]:border-0">
                            {furtherFilteredPOs.slice((page - 1) * 10, (page * 10)).map(po => (
                                <tr key={po.id} className="dark:bg-slate-800 border-b h-11 dark:border-b-slate-600 dark:text-gray-200 font-medium text-xs dark:hover:bg-slate-700/50 hover:bg-gray-200">
                                    <td className="px-2 text-left align-middle flex items-center group">
                                        {po.id}
                                        <TooltipProvider>
                                            <Tooltip>
                                                <TooltipTrigger asChild>
                                                    <Button
                                                        variant="ghost"
                                                        size="icon"
                                                        className="p-1 ml-2 bg-transparent hover:bg-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-200"
                                                        onClick={() => {
                                                            navigator.clipboard.writeText(po.id)
                                                            toast({
                                                                title: "Copied to clipboard",
                                                                duration: 2000,
                                                            })
                                                        }}
                                                    >
                                                        <CopyIcon className="h-3 w-3" />
                                                        <span className="sr-only">Copy to clipboard</span>
                                                    </Button>
                                                </TooltipTrigger>
                                                <TooltipContent>
                                                    <p>Click to copy</p>
                                                </TooltipContent>
                                            </Tooltip>
                                        </TooltipProvider>
                                    </td>
                                    <td className="px-2 text-left align-middle">{po.supplier}</td>
                                    <td className="px-2 text-left align-middle">{po.inco_terms}</td>
                                    <td className="px-2 text-left align-middle">{po.quantity}</td>
                                    <td className="px-2 text-left align-middle">{po.price_per_unit}</td>
                                    <td className="px-2 text-left align-middle">{po.unit}</td>
                                    <td className="px-2 text-left align-middle">{po.type}</td>
                                    <td className="px-2 text-left align-middle">
                                        {po.status === 'completed' &&
                                            <div className="flex gap-2 items-center">
                                                <div className="p-1 border dark:border-gray-400 dark:text-gray-400 rounded-full flex items-center justify-center">
                                                    <GitPullRequestClosed className="size-3" />
                                                </div>
                                                Closed
                                            </div>
                                        }
                                        {po.status === 'incomplete' &&
                                            <div className="flex gap-2 items-center">
                                                <div className="p-1 border dark:border-gray-400 dark:text-gray-400 rounded-full flex items-center justify-center">
                                                    <GitPullRequestDraft className="size-3" />
                                                </div>
                                                Pending
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        <Popover>
                                            <PopoverTrigger asChild className="mr-2 cursor-pointer">
                                                <Ellipsis className="size-4" />
                                            </PopoverTrigger>
                                            <PopoverContent align="end" className="w-fit p-1 flex flex-col">
                                                <Button variant="ghost" className="text-xs font-semibold h-8" onClick={() => handleCompletePO(po.id)}>
                                                    Complete
                                                </Button>
                                                <Button variant="ghost" className="text-xs font-semibold h-8">
                                                    Edit
                                                </Button>
                                                <Button variant="ghost" className="text-xs font-semibold h-8">
                                                    Send
                                                </Button>
                                                <Button variant="ghost" className="text-xs font-semibold h-8">
                                                    Ask
                                                </Button>
                                            </PopoverContent>
                                        </Popover>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="flex items-center dark:text-gray-200">
                <Pagination>
                    <PaginationContent>
                        <PaginationItem className="cursor-pointer">
                            <PaginationPrevious onClick={() => setPage(prev => {
                                if (prev - 1 > 0) {
                                    return prev - 1
                                }

                                return prev
                            })} />
                        </PaginationItem>
                        {new Array(Math.ceil(furtherFilteredPOs.length / 10)).fill(0).map((id, index) => (
                            <PaginationItem className="cursor-pointer">
                                <PaginationLink onClick={() => setPage(index + 1)}>{index + 1}</PaginationLink>
                            </PaginationItem>
                        ))}
                        <PaginationItem className="cursor-pointer">
                            <PaginationNext onClick={() => setPage(prev => {
                                if (prev + 1 <= Math.ceil(furtherFilteredPOs.length / 10)) {
                                    return prev + 1
                                }

                                return prev
                            })} />
                        </PaginationItem>
                    </PaginationContent>
                </Pagination>
            </div>
        </div>
    );
};

export default PurchaseOrders;