import React from 'react';

const items = [
    "Reorders",
    "Ordered Once",
    "PR Coated Nitrile Gloves",
    "DX Gloves String Knit",
    "Safety Gloves",
    "Fully Coated Gloves"
]

const Recommendations = () => {
    return (
        <div className="bg-gray-100 dark:bg-slate-700 p-6 rounded-lg">
            <h3 className="text-xl font-semibold mb-4 dark:text-gray-200">Recommended Items</h3>
            <div className="flex flex-col gap-2">
                {items.map((item) => (
                    <>
                        <span className="text-sm text-gray-600 dark:text-gray-400 font-semibold">{item}</span>
                        <div className="w-full h-[1px] bg-slate-500" />
                    </>
                ))}
            </div>
        </div>
    );
}

export default Recommendations;
