import React, { useState, useEffect } from 'react';
import { supabase, signOut } from './supabaseClient';
import Sidebar from './components/Sidebar';
import TopBar from './components/TopBar';
import Content from './components/Content';
import PurchaseOrders from './components/PurchaseOrders';
import Login from './components/Login';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Tickets from './components/Tickets';
import Dashboard from './components/Dashboard';
import Emails from './components/Emails'
import VerifyGRN from './components/VerifyGRN'
import { DarkModeProvider } from './components/new/DarkContext';
import { Activity } from 'lucide-react';
import InventoryManager from './components/InventoryManager';
import Tasks from './components/Tasks';
import SettingsPage from './components/SettingsPage';
import { cn } from './components/ui/cn';
import { useDarkMode } from './components/new/DarkContext';

//import './tailwind.css';

function App() {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

    useEffect(() => {
        const getSession = async () => {
            const { data, error } = await supabase.auth.getSession();
            if (data?.session) {
                setUser(data.session.user);
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        };
        getSession();
    }, []);

    const handleLogin = (userData) => {
        setUser(userData);
        setIsLoggedIn(true);
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const handleSignOut = async () => {
        await signOut();
        setIsLoggedIn(false);
        setUser(null);
    };

    return (
        <DarkModeProvider>
            <Router>
                <AppContent 
                    isLoggedIn={isLoggedIn}
                    user={user}
                    sidebarCollapsed={sidebarCollapsed}
                    setSidebarCollapsed={setSidebarCollapsed}
                    searchQuery={searchQuery}
                    handleLogin={handleLogin}
                    handleSearch={handleSearch}
                />
            </Router>
        </DarkModeProvider>
    );
}

function AppContent({ isLoggedIn, user, sidebarCollapsed, setSidebarCollapsed, searchQuery, handleLogin, handleSearch }) {
    const { dark } = useDarkMode();
    return (
    <div id="dark-provider-root" className={cn("h-screen flex font-sans", isLoggedIn && dark ? "dark" : "")}>
        {isLoggedIn ? (
            <>
                <Sidebar collapsed={sidebarCollapsed} setCollapsed={setSidebarCollapsed} user={user} />
                <div className="flex flex-col flex-1">
                    <TopBar
                        onSearch={handleSearch}
                        sidebarCollapsed={sidebarCollapsed}
                        setSidebarCollapsed={setSidebarCollapsed}
                        user={user}
                    />
                    <div className={cn("flex-1 overflow-y-auto no-scrollbar", dark ? "bg-slate-900" : "bg-white")}>
                        <Routes>
                            <Route path="/Tickets" element={<Tickets />} />
                            <Route path="/POs" element={<PurchaseOrders />} />
                            <Route path="/Tickets/:id" element={<Tickets />} />
                            <Route path="/POs/:id" element={<PurchaseOrders />} />
                            <Route path="/Emails" element={<Emails />} />
                            <Route path="/dashboard" element={<Dashboard user={user} />} />
                            <Route path="/documents" element={<VerifyGRN />} />
                            <Route path="/inventory" element={<InventoryManager />} />
                            <Route path="/tasks" element={<Tasks />} />
                            <Route path="/settings" element={<SettingsPage />} />
                            <Route path="/" element={<Content searchQuery={searchQuery} />} />
                        </Routes>
                    </div>
                </div>
            </>
        ) : (
            <div className="grid grid-cols-2 justify-center items-center w-full h-full bg-gray-100">
                <div className="bg-zinc-900 h-full flex flex-col justify-between text-gray-200 p-8">
                    <div className="flex gap-3 items-center">
                        <Activity className="size-8"/>
                        <span className="font-semibold text-2xl">Pulse AI</span>
                    </div>
                </div>
                <Login onLogin={handleLogin} />
            </div>
        )}
        </div>
    );
}

export default App;