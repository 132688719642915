import React, { useEffect, useState, useRef } from 'react';
import backend from '../api/axios';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { CSSTransition } from 'react-transition-group';
import { FaPlus, FaTimes } from 'react-icons/fa';
import '../assets/styles/formSlideAnimation.css';
import TitleBar from './TitleBar';
import { Button } from './ui/button';
import { Dialog, DialogClose, DialogContent, DialogTrigger } from './ui/dialog';
import { CirclePlus } from 'lucide-react';
import { cn } from './ui/cn';
import { useDarkMode } from './new/DarkContext';

const Tickets = () => {
    const { id } = useParams();
    const [openTickets, setOpenTickets] = useState([]);
    const [pendingTickets, setPendingTickets] = useState([]);
    const [closedTickets, setClosedTickets] = useState([]);
    const [showTicketDetails, setShowTicketDetails] = useState({});
    const [showCreateForm, setShowCreateForm] = useState(false);
    const [newTicket, setNewTicket] = useState({
        supplier: '',
        quantity: '',
        net_price: '',
        priority: '',
        person_assigned: '',
        part_number: '',
        status: 'open',
    });
    const [highlightedTicket, setHighlightedTicket] = useState(null);
    const refs = useRef({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    useEffect(() => {
        fetchTickets();
    }, []);

    useEffect(() => {
        if (id) {
            setHighlightedTicket(id);
            setShowTicketDetails((prevState) => ({
                ...prevState,
                [id]: true,
            }));
            if (refs.current[id]) {
                refs.current[id].scrollIntoView({ behavior: 'smooth', block: 'start' });
            }
        }
    }, [id]);

    const handleFileChange = (e) => {
        setNewTicket((prevState) => ({
            ...prevState,
            file: e.target.files[0],
        }));
    };

    const fetchTickets = async () => {
        try {
            const response = await backend.get(`/tickets`);
            const tickets = response.data;
            setOpenTickets(tickets.filter((ticket) => ticket.status === 'open'));
            setPendingTickets(tickets.filter((ticket) => ticket.status === 'pending'));
            setClosedTickets(tickets.filter((ticket) => ticket.status === 'closed'));
        } catch (error) {
            console.error('Error fetching tickets:', error);
        }
    };

    const toggleTicketDetails = (id) => {
        setShowTicketDetails((prevState) => ({
            ...prevState,
            [id]: !prevState[id],
        }));
    };

    const renderTickets = (tickets) =>
        tickets.map((ticket) => (
            <div
                key={ticket.id}
                ref={(el) => (refs.current[ticket.id] = el)}
                className={cn(
                    "p-4 border-b w-full",
                    dark ? "bg-slate-800 border-b-slate-600" : "bg-white border-b-gray-200",
                    highlightedTicket === ticket.id ? "border-2 border-blue-500" : ""
                )}
            >
                <div onClick={() => toggleTicketDetails(ticket.id)} className="cursor-pointer">
                <p className={cn(
                    "text-lg font-semibold",
                    dark ? "text-gray-300" : "text-gray-800"
                )}>
                    Ticket ID: {showTicketDetails[ticket.id] ? ticket.id : ticket.id.slice(0, 7)}
                </p>
                <p className={cn(
                    "text-sm font-semibold",
                    dark ? "text-gray-500" : "text-gray-600"
                )}>
                    <span className="font-medium">Supplier:</span> {ticket.supplier}
                </p>
            </div>
                {showTicketDetails[ticket.id] && (
                    <div className={cn(
                        "mt-4 p-4 rounded-lg",
                        dark ? "bg-slate-700 text-gray-400" : "bg-gray-100 text-gray-800"
                    )}>
                        <p className="mb-2">
                            <span className="font-semibold">Quantity:</span> {ticket.quantity}
                        </p>
                        <p className="mb-2">
                            <span className="font-semibold">Net Price:</span> {ticket.net_price}
                        </p>
                        <p className="mb-2">
                            <span className="font-semibold">Priority:</span> {ticket.priority}
                        </p>
                        <p className="mb-2">
                            <span className="font-semibold">Person Assigned:</span> {ticket.person_assigned}
                        </p>
                        <p className="mb-2">
                            <span className="font-semibold">Part Number:</span> {ticket.part_number}
                        </p>
                        {ticket.file_url && (
                            <p className="mb-2">
                                <span className="font-medium">File: </span>
                                <a
                                href={ticket.file_url}
                                download
                                target="_blank"
                                className={cn(
                                    "hover:text-blue-300",
                                    dark ? "text-blue-400" : "text-blue-600"
                                )}
                                rel="noopener noreferrer"
                            >
                                    {ticket.filename ? ticket.filename : 'View File'}
                                </a>
                            </p>
                        )}
                    </div>
                )}
            </div>
        ));

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewTicket((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCreateTicket = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        const formData = new FormData();
        Object.keys(newTicket).forEach((key) => {
            formData.append(key, newTicket[key]);
        });

        try {
            await backend.post(`/tickets`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            setNewTicket({
                supplier: '',
                quantity: '',
                net_price: '',
                priority: '',
                person_assigned: '',
                part_number: '',
                status: 'open',
                file: null,
            });
            setIsSuccess(true);
            fetchTickets(); // Refresh the tickets list
            setTimeout(() => {
                setIsSuccess(false);
                setShowCreateForm(false);
            }, 2000); // Close the form after 2 seconds
        } catch (error) {
            console.error('Error creating ticket:', error);
            // You might want to show an error message to the user here
        } finally {
            setIsLoading(false);
        }
    };

    const { dark } = useDarkMode()

    return (
        <div className={cn(
            "flex flex-col p-8 gap-2",
            dark ? "bg-slate-900 text-gray-200" : "bg-white text-gray-800"
        )}>
            <div>
                <Dialog>
                    <DialogTrigger asChild>
                        <Button variant="ghost" className={cn(
                            "flex items-center gap-2",
                            dark ? "text-gray-200 hover:bg-slate-700 hover:text-gray-200" : "text-gray-800 hover:bg-gray-100 hover:text-gray-900"
                        )}>
                            <CirclePlus className="size-4" />
                            Create Ticket
                        </Button>
                    </DialogTrigger>
                    <DialogContent className={cn(
                        dark && "bg-slate-700 border-slate-600"
                    )}>
                        <form onSubmit={handleCreateTicket} className={cn(
                            "space-y-4",
                            dark && "text-gray-400"
                        )}>
                            <div>
                                <label className="block font-semibold mb-2">Supplier</label>
                                <input
                                    type="text"
                                    name="supplier"
                                    value={newTicket.supplier}
                                    onChange={handleInputChange}
                                    className={cn(
                                        "w-full p-1.5 h-8 border text-gray-800 border-gray-200 rounded outline-none",
                                        dark && "text-gray-400 border-slate-600 bg-slate-700"
                                    )}
                                    required
                                />
                            </div>
                            <div>
                                <label className="block font-semibold mb-2">Quantity</label>
                                <input
                                    type="number"
                                    name="quantity"
                                    value={newTicket.quantity}
                                    onChange={handleInputChange}
                                    className={cn(
                                        "w-full p-1.5 h-8 border text-gray-800 border-gray-200 rounded outline-none",
                                        dark && "text-gray-400 border-slate-600 bg-slate-700"
                                    )}
                                    required
                                />
                            </div>
                            <div>
                                <label className="block font-semibold mb-2">Net Price</label>
                                <input
                                    type="number"
                                    name="net_price"
                                    value={newTicket.net_price}
                                    onChange={handleInputChange}
                                    className={cn(
                                        "w-full p-1.5 h-8 border text-gray-800 border-gray-200 rounded outline-none",
                                        dark && "text-gray-400 border-slate-600 bg-slate-700"
                                    )}
                                    required
                                />
                            </div>
                            <div>
                                <label className="block font-semibold mb-2">Priority</label>
                                <select
                                    name="priority"
                                    value={newTicket.priority}
                                    onChange={handleInputChange}
                                    className={cn(
                                        "w-full p-1.5 h-8 border text-gray-800 border-gray-200 rounded outline-none",
                                        dark && "text-gray-400 border-slate-600 bg-slate-700"
                                    )}
                                    required
                                >
                                    <option value="">Select Priority</option>
                                    <option value="High">High</option>
                                    <option value="Medium">Medium</option>
                                    <option value="Low">Low</option>
                                </select>
                            </div>
                            <div>
                                <label className="block font-semibold mb-2">Person Assigned</label>
                                <input
                                    type="text"
                                    name="person_assigned"
                                    value={newTicket.person_assigned}
                                    onChange={handleInputChange}
                                    className={cn(
                                        "w-full p-1.5 h-8 border text-gray-800 border-gray-200 rounded outline-none",
                                        dark && "text-gray-400 border-slate-600 bg-slate-700"
                                    )}
                                    required
                                />
                            </div>
                            <div>
                                <label className="block font-semibold mb-2">Part Number</label>
                                <input
                                    type="text"
                                    name="part_number"
                                    value={newTicket.part_number}
                                    onChange={handleInputChange}
                                    className={cn(
                                        "w-full p-1.5 h-8 border text-gray-800 border-gray-200 rounded outline-none",
                                        dark && "text-gray-400 border-slate-600 bg-slate-700"
                                    )}
                                    required
                                />
                            </div>
                            <div>
                                <label className="block font-semibold mb-2">File</label>
                                <input
                                    type="file"
                                    name="file"
                                    onChange={handleFileChange}
                                    className={cn(
                                        "w-full p-1.5 h-11 border text-gray-800 border-gray-200 rounded outline-none",
                                        dark && "text-gray-400 border-slate-600 bg-slate-700"
                                    )}
                                />
                            </div>
                            <div className="relative">
                                <DialogClose>
                                    <button
                                        type="submit"
                                        className={`w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                        disabled={isLoading}
                                    >
                                        {isLoading ? 'Creating...' : 'Create Ticket'}
                                    </button>
                                </DialogClose>
                                {isLoading && (
                                    <div className="absolute inset-0 flex items-center justify-center">
                                        <div className="w-5 h-5 border-t-2 border-blue-200 border-solid rounded-full animate-spin"></div>
                                    </div>
                                )}
                            </div>
                            {isSuccess && (
                                <div className="mt-4 p-2 bg-green-100 text-green-700 rounded">
                                    Ticket created successfully!
                                </div>
                            )}
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
            <div className={cn(
                "border shadow-md rounded-lg grid grid-cols-3 h-[580px] max-h-[580px]",
                dark ? "border-slate-600 bg-slate-800" : "border-gray-200 bg-white"
            )}>
                <div className={cn(
                    "flex flex-col border-r",
                    dark ? "border-r-slate-600" : "border-r-gray-200"
                )}>
                    <div className={cn(
                        "flex justify-center items-center h-[52px] border-b",
                        dark ? "border-b-slate-600" : "border-b-gray-200"
                    )}>
                        <span className={cn(
                            "text-xl font-semibold",
                            dark ? "text-gray-200" : "text-gray-800"
                        )}>Open Tickets</span>
                    </div>
                    <div className="h-[520px] overflow-scroll no-scrollbar">
                        {renderTickets(openTickets)}
                    </div>
                </div>
                <div className={cn(
                    "flex flex-col border-r",
                    dark ? "border-r-slate-600" : "border-r-gray-200"
                )}>
                    <div className={cn(
                        "flex justify-center items-center h-[52px] border-b",
                        dark ? "border-b-slate-600" : "border-b-gray-200"
                    )}>
                        <span className={cn(
                            "text-xl font-semibold",
                            dark ? "text-gray-200" : "text-gray-800"
                        )}>Pending Tickets</span>
                    </div>
                    <div className="h-[520px] overflow-scroll no-scrollbar">
                        {renderTickets(pendingTickets)}
                    </div>
                </div>
                <div className={cn(
                    "flex flex-col border-r",
                    dark ? "border-r-slate-600" : "border-r-gray-200"
                )}>
                    <div className={cn(
                        "flex justify-center items-center h-[52px] border-b",
                        dark ? "border-b-slate-600" : "border-b-gray-200"
                    )}>
                        <span className={cn(
                            "text-xl font-semibold",
                            dark ? "text-gray-200" : "text-gray-800"
                        )}>Closed Tickets</span>
                    </div>
                    <div className="h-[520px] overflow-scroll no-scrollbar">
                        {renderTickets(closedTickets)}
                    </div>
                </div>
            </div>
        </div>
    );
};

/*
<>
      <div className="flex flex-col w-full">
        <div className="flex flex-col justify-center items-center h-full overflow-hidden">
          <div className="flex flex-col w-full px-12 py-6 overflow-hidden">
            <div className="grid grid-cols-3 gap-4 mb-4">
              {['Open Tickets', 'Pending Tickets', 'Closed Tickets'].map((header, index) => (
                <div key={index} className="w-full rounded-lg text-sm font-semibold px-4 py-2 bg-white text-gray-900 text-center">
                  {header}
                </div>
              ))}
            </div>
            <div className="flex flex-row h-full">
              <div className="flex-1 bg-gray-100 p-4 rounded shadow h-96 overflow-y-auto">
                
              </div>
              <div className="flex-1 bg-gray-100 p-4 rounded shadow h-96 overflow-y-auto mx-2">
                
              </div>
              <div className="flex-1 bg-gray-100 p-4 rounded shadow h-96 overflow-y-auto">
                
              </div>
            </div>
            <button
              className="bg-green-500 text-white py-2 px-4 rounded flex items-center mt-4 font-semibold"
              onClick={() => setShowCreateForm(!showCreateForm)}
              style={{ alignSelf: 'flex-end' }}
            >
              <FaPlus className="mr-2" />
              Create
            </button>
            <CSSTransition in={showCreateForm} timeout={300} classNames="form-slide" unmountOnExit>
              <div className="fixed top-0 right-0 h-full w-1/3 bg-white shadow-lg p-6 overflow-y-auto z-50">
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-gray-800">Create New Ticket</h2>
                  <button
                    className="text-gray-500 hover:text-gray-700"
                    onClick={() => setShowCreateForm(false)}
                  >
                    <FaTimes size={24} />
                  </button>
                </div>
                {isSuccess && (
                  <div className="mb-4 p-2 bg-green-100 text-green-700 rounded">
                    Ticket created successfully!
                  </div>
                )}
                
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    </>
*/

export default Tickets;