import React from 'react';
import { faChartBar, faQuoteRight, faFileAlt, faTasks, faTicketAlt, faShoppingCart, faEnvelope, faFile } from '@fortawesome/free-solid-svg-icons';
import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import MenuItem from './new/MenuItem';
import { Activity, AlignJustify } from 'lucide-react';
import { Button } from './ui/button';
import { useDarkMode } from './new/DarkContext';
import { cn } from './ui/cn';

const main_menu = [
    {
        name: "Tasks",
        icon: faTasks,
        href: "/tasks"
    },
    {
        name: "Tickets",
        icon: faTicketAlt,
        href: "/tickets"
    },
    {
        name: "Purchase Orders",
        icon: faShoppingCart,
        href: "/POs"
    },
    {
        name: "Emails",
        icon: faEnvelope,
        href: "/Emails"
    },
    {
        name: "Documents",
        icon: faFile,
        href: "/documents"
    }
]

const user_menu = [
    {
        name: "Sales",
        icon: faChartBar,
        href: "#sales"
    },
    {
        name: "Quotes",
        icon: faQuoteRight,
        href: "#quotes"
    },
    {
        name: "Reporting",
        icon: faFileAlt,
        href: "/"
    }
]


const Sidebar = ({ collapsed, setCollapsed, user }) => {
    const { dark } = useDarkMode();
    
    return (
        <div className={cn(
            "p-4 border-r flex flex-col h-screen transition-all duration-300 ease-in-out",
            collapsed ? "w-16" : "w-64",
            dark ? "bg-slate-800 text-gray-200" : "bg-white text-gray-800"
        )}>
            <div className="flex gap-2 items-center justify-between">
                <div className="flex gap-3 items-center">
                    <Activity className="size-10"/>
                    {!collapsed && <span className={cn("ml-2 text-sm font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Pulse AI</span>}
                </div>
                <AlignJustify className="cursor-pointer" onClick={() => setCollapsed(true)}/>
            </div>
            <div className={cn("flex flex-col items-center justify-center py-8", collapsed ? "h-auto" : "h-52")}>
                <Avatar className={cn("border-2", dark ? "border-slate-600" : "border-gray-200", collapsed ? "h-12 w-12" : "h-20 w-20")}>
                    <AvatarImage />
                    <AvatarFallback className={cn(
                        dark ? "bg-slate-700" : "bg-gray-200"
                    )}>
                        <span className={cn(
                        dark ? "text-gray-200" : "text-gray-800"
                    )}>{user?.user_metadata?.name ? user.user_metadata.name.split(' ').map(n => n[0]).join('').toUpperCase() : 'U'}</span>
                    </AvatarFallback>
                </Avatar>
                {!collapsed && (
                    <div className="mt-4 text-center">
                        <span className="font-semibold text-lg leading-6">{user?.user_metadata?.name || 'User'}</span>
                        <span className={cn("block text-sm", dark ? "text-gray-500" : "text-gray-400")}>{user?.user_metadata?.companyName || 'Company'}</span>
                    </div>
                )}
            </div>
            <div className="flex-1 overflow-y-auto">
                <div className="mb-8">
                    {!collapsed && <span className="px-4 font-semibold text-xs text-gray-500 mb-2">MAIN</span>}
                    {main_menu.map((item) => (
                        <MenuItem key={item.name} to={item.href} icon={item.icon} collapsed={collapsed}>{item.name}</MenuItem>
                    ))}
                </div>
                <div>
                    {!collapsed && <span className="px-4 font-semibold text-xs text-gray-500 mb-2">USER</span>}
                    {user_menu.map((item) => (
                        <MenuItem key={item.name} to={item.href} icon={item.icon} collapsed={collapsed}>{item.name}</MenuItem>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Sidebar