import React from 'react';
import '../assets/styles/titleBar.css'
import { cn } from './ui/cn';
import { useDarkMode } from './new/DarkContext';

const TitleBar = ({ pageName }) => {
  const { dark } = useDarkMode();
  return (
    <section className={cn(
      "is-title-bar",
      dark ? "bg-slate-900 text-gray-200" : "bg-white text-gray-800"
    )}>
      <div className="flex flex-col md:flex-row items-center justify-between">
        <ul>
          <li>Admin</li>
          <li>{pageName}</li>
        </ul>
      </div>
    </section>
  );
};

export default TitleBar;