import React, { useState, useEffect } from 'react';
import { FaSearch, FaTimes } from 'react-icons/fa';
import SearchModal from './SearchModal';
import backend from '../api/axios';
import { useNavigate } from 'react-router-dom';
import UserDetails from './UserDetails';
import DatePicker from 'react-datepicker';
import NotificationMenu from './NotficationMenu';
import "react-datepicker/dist/react-datepicker.css";
import SearchCommandPopover from './new/SearchCommandPopover';
import { AlignJustify } from 'lucide-react';
import { Switch } from './ui/switch';
import { Label } from './ui/label';
import { useDarkMode } from './new/DarkContext';
import { cn } from './ui/cn';

const TopBar = ({ onSearch, sidebarCollapsed, setSidebarCollapsed, user }) => {
    const [filteredResults, setFilteredResults] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [description, setDescription] = useState(''); // Add a state for description
    const navigate = useNavigate();
    const [prevQuery, setPrevQuery] = useState('');
    const [isTyping, setIsTyping] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [prevStartDate, setPrevStartDate] = useState(null);
    const [prevEndDate, setPrevEndDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);

    const { dark, toggleDark } = useDarkMode();

    useEffect(() => {
        if (selectedOption && selectedOption.type === 'supplier') {
            const fetchSuppliers = async () => {
                try {
                    const response = await backend.get('/suppliers');
                    setSuppliers(response.data.suppliers);
                } catch (error) {
                    console.error('Error fetching suppliers:', error);
                }
            };

            fetchSuppliers();
        }
    }, [selectedOption]);

    /*
    const handleSearchChange = (e) => {
        const value = e.target.value;
        setQuery(value);
        onSearch(value);
    };
    

    const handleResultClick = (result) => {
        if (!result) {
            closeModal();
        } else if (result.type === 'date') {
            setSelectedOption(result);
            setIsDropdownVisible(false);
        } else if (result.type === 'supplier') {
            setSelectedOption(result);
        } else if (result.type === 'po') {
            navigate(`/pos/${result.data.id}`);
        } else {
            navigate(`/tickets/${result.ticket.id}`);
        }
        closeModal();
        if (result.type === 'supplier') setIsDropdownVisible(true);
    };

    const handleKeyPress = async (e) => {
        if (e.key === 'Enter') {
            if (query !== prevQuery || startDate !== prevStartDate || endDate !== prevEndDate) {
                setIsTyping(false);
                setIsDropdownVisible(false);
                setIsLoading(true);
                setIsModalOpen(true);
                try {
                    const response = await backend.post(`/search_files`, { query, startDate, endDate, supplier: selectedSupplier });
                    const results = response.data.results.map((result, index) => ({
                        id: index,
                        type: result.type,
                        data: result.data,
                        ticket: result.ticket,
                        inclusions: result.inclusions,
                        similarity: result.similarity,
                    }));
                    setFilteredResults(results);
                    setDescription(response.data.description); // Set the description from the response
                    console.log(response.data.description)
                    await backend.post('/searches', { user_id: userId, search: query });
                    const recentSearchesResponse = await backend.get(`/searches?user_id=${userId}`);
                    setSearches(recentSearchesResponse.data);
                } catch (error) {
                    console.error('Error filtering search results:', error);
                } finally {
                    setPrevQuery(query);
                    setPrevStartDate(startDate);
                    setPrevEndDate(endDate);
                    setIsLoading(false);
                }
            } else if (query || startDate || endDate || selectedSupplier) {
                setIsDropdownVisible(false);
                setIsModalOpen(true);
            }
        } else if (e.key === 'Escape' || e.key === 'Esc') {
            closeModal();
            setIsTyping(false);
            setIsDropdownVisible(false);
        } else {
            setIsTyping(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setIsDropdownVisible(false);
    };

    const handleInputFocus = () => {
        setIsDropdownVisible(true);
    };
    */

    



    return (
        <div className={cn(
            "flex justify-between items-center p-4 border-b",
            dark ? "bg-slate-900 text-gray-200 border-slate-700" : "bg-white text-gray-800 border-gray-200"
        )}>
            <div className="flex items-center gap-6">
                <AlignJustify className={`${!sidebarCollapsed && "hidden"} cursor-pointer`} onClick={() => setSidebarCollapsed(false)}/>
                <span className={cn("flex items-center gap-2 font-semibold text-sm", dark ? "text-gray-400" : "text-gray-800")}>
                    <Switch 
                        id="mode" 
                        className="data-[state=checked]:bg-slate-700 data-[state=unchecked]:bg-gray-200" 
                        thumbClassName="data-[state=checked]:bg-slate-400"
                        checked={dark}
                        onCheckedChange={toggleDark}
                    />
                    <Label htmlFor="mode">Dark Mode</Label>
                </span>
            </div>
            <SearchCommandPopover />
            <div className="flex gap-2 items-center justify-center">
                <NotificationMenu apiKey="AIzaSyCAuFJ2V5N96GAL_Ykdwpn9Mkfj4kkG4F0" />
                <UserDetails user={user} />
            </div>
        </div>
    );
};

export default TopBar;