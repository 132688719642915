import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

// Request values from the backend to display an analytical graph
const Reporting = () => {
    const [timeRange, setTimeRange] = useState('7d');

    const data = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul'],
        datasets: [
            {
                label: 'Sales',
                data: [12000, 19000, 3000, 5000, 2000, 30000, 45000],
                fill: false,
                backgroundColor: 'rgba(0, 0, 255, 0.1)',
                borderColor: 'rgba(100, 100, 255)',
                tension: 0.4
            },
        ],
    };

    const options = {
        scales: {
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                },
            },
            x: {
                grid: {
                    display: false,
                },
                ticks: {
                    display: true,
                },
            }
        },
        plugins: {
            legend: {
                display: false
            }
        }
    };

    const handleTimeRangeChange = (range) => {
        setTimeRange(range);
        // Update data based on the selected time range
    };

    return (
        <div className="flex flex-col gap-6 col-span-2">
            <h2 className="text-2xl font-semibold dark:text-gray-200">Sales</h2>
            <Line data={data} options={options} />
            <div className="flex gap-4">
                <button
                    className={`px-4 py-2 rounded-lg transition-colors duration-200 ${timeRange === '7d' ? 'bg-gray-200 dark:bg-blue-500 dark:text-white' : 'dark:bg-slate-700 dark:hover:bg-slate-600 dark:text-gray-200'}`}
                    onClick={() => handleTimeRangeChange('7d')}
                >
                    Last 7 Days
                </button>
                <button
                    className={`px-4 py-2 rounded-lg transition-colors duration-200 ${timeRange === '1m' ? 'bg-gray-200 dark:bg-blue-500 dark:text-white' : 'dark:bg-slate-700 dark:hover:bg-slate-600 dark:text-gray-200'}`}
                    onClick={() => handleTimeRangeChange('1m')}
                >
                    Last Month
                </button>
                <button
                    className={`px-4 py-2 rounded-lg transition-colors duration-200 ${timeRange === '3m' ? 'bg-gray-200 dark:bg-blue-500 dark:text-white' : 'dark:bg-slate-700 dark:hover:bg-slate-600 dark:text-gray-200'}`}
                    onClick={() => handleTimeRangeChange('3m')}
                >
                    Last 3 Months
                </button>
            </div>
        </div>

    );
};

export default Reporting;