import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { Button } from '../ui/button';

const MenuItem = ({ to, icon, children }) => {
    return (
        <Button asChild size="sm" variant="ghost" className="dark:hover:bg-gray-700 dark:hover:text-white flex items-center justify-start gap-2">
            <Link to={to}>
                <FontAwesomeIcon icon={icon} color="rgb(156 163 175)" />
                {children}
            </Link>
        </Button>
    )
}

export default MenuItem