import React, { useState } from 'react';
import { Avatar, AvatarFallback } from './ui/avatar';

const Opportunity = () => {
  const [opportunities, setOpportunities] = useState([
    {
      id: 1, name: 'Pulse.ai - 1', location: 'San Francisco, CA', recommendedItems: 25,
      potentialValue: '$12,345'
    },
    { id: 2, name: 'Pulse.ai - 2', location: 'Seattle, WA', recommendedItems: 100, potentialValue: '$50,000' },
    {
      id: 3, name: 'Pulse.ai - 3', location: 'New York, NY', recommendedItems: 150, potentialValue:
        '$987,654'
    },
  ]);

  const handleAddOpportunity = () => {
    const newOpportunity = { id: opportunities.length + 1, name: 'New Opportunity', location: 'New Location', recommendedItems: 0, potentialValue: '$0' };
    setOpportunities([...opportunities, newOpportunity]);
  };

  return (
    <div className="bg-white dark:bg-slate-800 p-4 rounded-lg border-[1.4px] border-gray-200 dark:border-slate-700 flex flex-col gap-2 h-48 overflow-scroll no-scrollbar shadow-md">
        {opportunities.map((opportunity, index) => (
            <div className="flex rounded-lg items-center justify-between p-2 dark:text-gray-200">
            <div className="flex gap-2 items-center">
                <span className="text-sm font-semibold">{opportunity.location}</span>
                <span className="text-xs text-gray-400 dark:text-gray-500">{opportunity.potentialValue}</span>
            </div>
            <span className="text-xs font-semibold dark:bg-slate-800 rounded-full px-2.5 py-1 border dark:border-slate-700">{opportunity.name}</span>
        </div>
        ))}
        <button className="sticky bottom-0 rounded-lg w-full bg-gray-100 hover:bg-gray-200 dark:bg-green-600 dark:text-white font-semibold py-1.5" onClick={handleAddOpportunity}>
            Add opportunity
        </button>
    </div>
    
  );
};

/*
<div className="bg-white p-6 rounded-lg shadow-md">
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b border-gray-200 text-left text-gray-600">Name</th>
              <th className="py-2 px-4 border-b border-gray-200 text-left text-gray-600">Location</th>
              <th className="py-2 px-4 border-b border-gray-200 text-left text-gray-600">Recommended Items</th>
              <th className="py-2 px-4 border-b border-gray-200 text-left text-gray-600">Potential Value</th>
            </tr>
          </thead>
          <tbody>
            {opportunities.map((opportunity) => (
              <tr key={opportunity.id} className="hover:bg-gray-100 transition-colors duration-150">
                <td className="py-2 px-4 border-b border-gray-200">{opportunity.name}</td>
                <td className="py-2 px-4 border-b border-gray-200">{opportunity.location}</td>
                <td className="py-2 px-4 border-b border-gray-200">{opportunity.recommendedItems}</td>
                <td className="py-2 px-4 border-b border-gray-200">{opportunity.potentialValue}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <button
        onClick={handleAddOpportunity}
        className="bg-gray-500 text-white py-2 px-4 rounded-lg mt-4 w-full hover:bg-green-600 transition duration-200"
      >
        Add Opportunity
      </button>
    </div>
*/

export default Opportunity;