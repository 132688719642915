import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FaTimes } from 'react-icons/fa';
import ReactMarkdown from 'react-markdown';
import { useDarkMode } from './new/DarkContext';
import { cn } from './ui/cn';

Modal.setAppElement('#root');

const SearchModal = ({ isOpen, onRequestClose, searchResults, isLoading, onResultClick, description }) => {
  const [sortOption, setSortOption] = useState('relevance');
  const [typeOption, setTypeOption] = useState('ticket');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [peopleFilter, setPeopleFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [uniquePeople, setUniquePeople] = useState([]);
  const [showLessRelevant, setShowLessRelevant] = useState(false);
  const resultsPerPage = 10;
  const similarityThreshold = 0.7;
  const { dark } = useDarkMode();

  useEffect(() => {
    console.log(searchResults)
    const people = [...new Set(searchResults.map(result => result.ticket.person_assigned).filter(Boolean))];
    setUniquePeople(people);
  }, [searchResults]);

  const handleSortChange = (e) => {
    setSortOption(e.target.value);
  };

  const handleTabChange = (type) => {
    setTypeOption(type);
    setCurrentPage(1);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };

  const handlePeopleFilterChange = (person) => {
    setPeopleFilter((prev) => 
      prev.includes(person) ? prev.filter(p => p !== person) : [...prev, person]
    );
  };

  const sortResults = (results) => {
    if (sortOption === 'date') {
      return [...results].sort((a, b) => new Date(b.ticket.created_at) - new Date(a.ticket.created_at));
    }
    return [...results].sort((a, b) => b.similarity - a.similarity);
  };

  const filterResultsByType = (results) => {
    if (typeOption === 'file') return results.filter(result => result.ticket.file_url != null);
    return results.filter(result => result.type === 'ticket' || result.type === 'po');
  };

  const filterResultsByDate = (results) => {
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
    return results.filter(result => {
      const createdAt = new Date(result.ticket.created_at);
      if (start && createdAt < start) return false;
      if (end && createdAt > end) return false;
      return true;
    });
  };

  const filterResultsByPeople = (results) => {
    if (peopleFilter.length === 0) return results;
    return results.filter(result => peopleFilter.includes(result.ticket.person_assigned));
  };

  const sortedResults = sortResults(searchResults);
  const filteredByTypeResults = filterResultsByType(sortedResults);
  const filteredByDateResults = filterResultsByDate(filteredByTypeResults);
  const finalFilteredResults = filterResultsByPeople(filteredByDateResults);

  const mainResults = finalFilteredResults.filter(result => result.similarity >= similarityThreshold);
  const lessRelevantResults = finalFilteredResults.filter(result => result.similarity < similarityThreshold);

  const totalPages = Math.ceil(mainResults.length / resultsPerPage);
  const displayedResults = mainResults.slice((currentPage - 1) * resultsPerPage, currentPage * resultsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const toggleLessRelevant = () => {
    setShowLessRelevant(!showLessRelevant);
  };

  const renderDescription = (desc) => {
    return (
      <ReactMarkdown
        components={{
          p: ({ node, ...props }) => <TicketParagraph {...props} />,
          strong: ({ node, ...props }) => <TicketParagraph {...props} component="strong" />,
          em: ({ node, ...props }) => <TicketParagraph {...props} component="em" />,
        }}
      >
        {desc}
      </ReactMarkdown>
    );
  };

  const TicketParagraph = ({ children, component = 'span', ...props }) => {
    const Component = component;
    const processedChildren = React.Children.map(children, (child) => {
      if (typeof child === 'string') {
        return processText(child);
      } else if (child.props && child.props.children) {
        return React.cloneElement(child, {
          children: React.Children.map(child.props.children, (nestedChild) => 
            typeof nestedChild === 'string' ? processText(nestedChild) : nestedChild
          )
        });
      }
      return child;
    });

    return <Component {...props}>{processedChildren}</Component>;
  };

  const processText = (text) => {
    const parts = text.split(/(\[UUID: [^\]]+\])/g);
    return parts.map((part, index) => {
      const match = part.match(/\[UUID: ([^\]]+)\]/);
      if (match) {
        const ticketId = match[1];
        return (
          <span
            key={index}
            className={cn("text-blue-600 cursor-pointer hover:underline", dark ? "text-gray-300" : "text-blue-600")}
            onClick={(e) => {
              e.stopPropagation();
              handleTicketIdClick(ticketId);
            }}
          >
            {ticketId}
          </span>
        );
      }
      return part;
    });
  };

  const handleTicketIdClick = (ticketId) => {
    const ticket = searchResults.find(result => result.ticket.id === ticketId);
    if (ticket) {
      onResultClick(ticket);
    } else {
      console.log(`Ticket with ID ${ticketId} not found in search results`);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={cn(
        "absolute inset-0 flex items-center justify-center",
        dark ? "bg-slate-900 bg-opacity-75" : "bg-gray-100 bg-opacity-75"
      )}
      overlayClassName={cn(
        "fixed inset-0",
        dark ? "bg-black bg-opacity-60" : "bg-white bg-opacity-60"
      )}
    >
      <div className={cn(
        "shadow-xl rounded-lg p-8 w-[95vw] h-[95vh] max-w-[1600px] overflow-hidden flex",
        dark ? "bg-slate-800 text-gray-200" : "bg-white text-gray-800"
      )}>
        {/* Sidebar */}
        <div className={cn("w-64 p-4 mr-4 overflow-y-auto", dark ? "bg-slate-700" : "bg-gray-100")}>
          <h3 className={cn("text-lg font-semibold mb-4", dark ? "text-gray-200" : "text-gray-800")}>Filters</h3>
          <div className="mb-4">
            <label htmlFor="sort" className={cn("block mb-2", dark ? "text-gray-300" : "text-gray-600")}>Sort by:</label>
            <select
              id="sort"
              value={sortOption}
              onChange={handleSortChange}
              className={cn("w-full p-2 border rounded", dark ? "bg-slate-600 text-gray-200 border-slate-500" : "bg-white text-gray-800 border-gray-300")}
            >
              <option value="relevance">Relevance</option>
              <option value="date">Date</option>
            </select>
          </div>
          <div className="mb-4">
            <h4 className={cn("font-semibold mb-2", dark ? "text-gray-300" : "text-gray-800")}>Filter by person:</h4>
            {uniquePeople.map(person => (
              <label key={person} className={cn("flex items-center mb-2", dark ? "text-gray-300" : "text-gray-800")}>
                <input
                  type="checkbox"
                  checked={peopleFilter.includes(person)}
                  onChange={() => handlePeopleFilterChange(person)}
                  className={cn("mr-2", dark ? "bg-slate-600 border-slate-500" : "bg-white border-gray-300")}
                />
                {person}
              </label>
            ))}
          </div>
        </div>

        {/* Main content */}
        <div className="flex-1 flex flex-col overflow-hidden">
          <div className="flex justify-between items-center mb-6">
            <h2 className={cn("text-3xl font-bold", dark ? "text-gray-200" : "text-gray-800")}>Search Results</h2>
            <button onClick={onRequestClose} className={cn("transition duration-300", dark ? "text-gray-400 hover:text-gray-200" : "text-gray-600 hover:text-gray-800")}>
              <FaTimes size={28} />
            </button>
          </div>
          <div className="mb-6 flex space-x-4">
            <button 
              onClick={() => handleTabChange('ticket')} 
              className={cn(
                "px-6 py-3 text-lg rounded-md transition duration-300",
                typeOption === 'ticket' ? cn("bg-blue-500 text-white", dark ? "bg-blue-500 text-gray-200" : "bg-blue-500 text-white") : cn("bg-gray-200 hover:bg-gray-300", dark ? "bg-slate-800 hover:bg-slate-700 text-gray-200" : "bg-gray-200 hover:bg-gray-300 text-gray-800")
              )}
            >
              Tickets
            </button>
            <button 
              onClick={() => handleTabChange('file')} 
              className={cn(
                "px-6 py-3 text-lg rounded-md transition duration-300",
                typeOption === 'file' ? cn("bg-blue-500 text-white", dark ? "bg-blue-500 text-gray-200" : "bg-blue-500 text-white") : cn("bg-gray-200 hover:bg-gray-300", dark ? "bg-slate-800 hover:bg-slate-700 text-gray-200" : "bg-gray-200 hover:bg-gray-300 text-gray-800")
              )}
            >
              Files
            </button>
          </div>
          <div className="flex-grow overflow-y-auto pr-4">
            {isLoading ? (
              <p className={cn("italic text-xl", dark ? "text-gray-400" : "text-gray-600")}>Loading...</p>
            ) : (
              <>
                {description && (
                  <div className={cn(
                    "mb-8 p-6 rounded-lg shadow-md",
                    dark ? "bg-slate-700" : "bg-gradient-to-r from-blue-50 to-indigo-50"
                  )}>
                    <h3 className={cn("text-2xl font-semibold mb-4", dark ? "text-indigo-300" : "text-indigo-700")}>Result Summary</h3>
                    <div className={cn("prose max-w-none text-lg", dark ? "text-gray-300" : "text-gray-800")}>
                      {renderDescription(description)}
                    </div>
                  </div>
                )}
                {displayedResults.map((result, index) => (
                  <div key={index} className={cn(
                    "mb-8 p-6 border rounded-lg shadow-md hover:shadow-lg transition duration-300 cursor-pointer",
                    dark ? "bg-slate-700 text-gray-200 hover:bg-slate-600 border-slate-600" : "bg-white text-gray-800 hover:bg-gray-100 border-gray-200"
                  )} onClick={() => onResultClick(result)}>
                    {result.type === 'file' ? (
                      <>
                        {result.ticket.file_url && (
                          <p className="mb-3 text-lg">
                            <span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>File:</span>{' '}
                            <a href={result.ticket.file_url} target="_blank" rel="noopener noreferrer" className={cn("text-blue-600 hover:underline", dark ? "text-gray-300" : "text-blue-600")}>
                              {result.ticket.filename || "View File"}
                            </a>
                          </p>
                        )}
                      </>
                    ) : (
                      <>
                        {result.ticket.id && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Ticket ID:</span> {result.ticket.id}</p>}
                        {result.ticket.supplier && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Supplier:</span> {result.ticket.supplier}</p>}
                        {result.ticket.person_assigned && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Person Assigned:</span> {result.ticket.person_assigned}</p>}
                        {result.ticket.description && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Description:</span> {result.ticket.description}</p>}
                        {result.ticket.status && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Status:</span> {result.ticket.status}</p>}
                        {result.ticket.quantity && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Quantity:</span> {result.ticket.quantity}</p>}
                        {result.ticket.net_price && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Net Price:</span> {result.ticket.net_price}</p>}
                      </>
                    )}
                  </div>
                ))}
                {lessRelevantResults.length > 0 && (
                  <div className="mt-6">
                    <button onClick={toggleLessRelevant} className={cn("text-blue-600 hover:underline transition duration-300", dark ? "text-gray-300" : "text-blue-600")}>
                      {showLessRelevant ? "Hide less relevant results" : "Show less relevant results"}
                    </button>
                    {showLessRelevant && lessRelevantResults.map((result, index) => (
                      <div key={index} className={cn(
                        "mt-4 p-4 border rounded-lg shadow-md hover:shadow-lg transition duration-300 cursor-pointer",
                        dark ? "bg-slate-700 text-gray-200 hover:bg-slate-600 border-slate-600" : "bg-white text-gray-800 hover:bg-gray-100 border-gray-200"
                      )} onClick={() => onResultClick(result)}>
                        {result.type === 'file' ? (
                          <>
                            {result.ticket.file_url && (
                              <p className="mb-2 text-lg">
                                <span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>File:</span>{' '}
                                <a href={result.ticket.file_url} target="_blank" rel="noopener noreferrer" className={cn("text-blue-600 hover:underline", dark ? "text-gray-300" : "text-blue-600")}>
                                  {result.ticket.filename || "View File"}
                                </a>
                              </p>
                            )}
                          </>
                        ) : (
                          <>
                            {result.ticket.id && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Ticket ID:</span> {result.ticket.id}</p>}
                            {result.ticket.supplier && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Supplier:</span> {result.ticket.supplier}</p>}
                            {result.ticket.person_assigned && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Person Assigned:</span> {result.ticket.person_assigned}</p>}
                            {result.ticket.description && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Description:</span> {result.ticket.description}</p>}
                            {result.ticket.status && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Status:</span> {result.ticket.status}</p>}
                            {result.ticket.quantity && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Quantity:</span> {result.ticket.quantity}</p>}
                            {result.ticket.net_price && <p className="mb-2 text-lg"><span className={cn("font-semibold", dark ? "text-gray-300" : "text-gray-800")}>Net Price:</span> {result.ticket.net_price}</p>}
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="mt-6">
            <div className="flex justify-center items-center">
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i}
                  className={cn(
                    "mx-1 px-3 py-1 rounded-md transition duration-300",
                    currentPage === i + 1
                      ? dark ? "bg-blue-600 text-white" : "bg-blue-500 text-white"
                      : dark ? "bg-slate-700 text-gray-300 hover:bg-slate-600" : "bg-gray-200 text-gray-800 hover:bg-gray-300"
                  )}
                  onClick={() => handlePageChange(i + 1)}
                >
                  {i + 1}
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SearchModal;