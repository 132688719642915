import React, { useState } from 'react';
import { HoverCard, HoverCardTrigger, HoverCardContent } from './ui/hover-card';
import { Avatar, AvatarFallback } from './ui/avatar';
import { Button } from './ui/button';
import { useDarkMode } from './new/DarkContext';
import { cn } from './ui/cn';

const Customer = ({ searchQuery }) => {
    const [customers, setCustomers] = useState([
        {
            name: 'Pulse AI',
            address: '86 West St, NM',
            contact: 'Ritvik Pandey',
            phone: '925-867-5309',
            email: 'ritvik@trypulse.ai',
        },
        {
            name: 'Pulse AI',
            address: '94 South St, NM',
            contact: 'Siddharth Manchkanti',
            phone: '925-167-5339',
            email: 'sid@trypulse.ai',
        },
        {
            name: 'Pulse AI',
            address: '72 North St, NM',
            contact: 'Dhruv Sharma',
            phone: '925-207-1109',
            email: 'dhruv@trypulse.ai',
        },
    ]);

    const { dark } = useDarkMode();

    const handleCustomerEdit = (index) => {
        const newName = prompt('Enter new customer name:', customers[index].name);
        if (newName) {
            setCustomers(prev => {
                const newCustomers = [...prev];
                newCustomers[index].name = newName;
                return newCustomers;
            });
        }
    }

    return (
        <div className={cn(
            "p-4 rounded-lg border-[1.4px] flex flex-col gap-2 h-48 overflow-scroll no-scrollbar",
            dark ? "bg-slate-800 border-slate-700 text-gray-200" : "bg-white border-gray-200 text-gray-800"
        )}>
            {customers.map((customer, index) => (
                <HoverCard key={index}>
                    <HoverCardTrigger>
                        <div className="flex rounded-lg items-center justify-between p-2">
                            <div className="flex gap-2 items-center">
                                <Avatar>
                                    <AvatarFallback className={cn(
                                        "border-2",
                                        dark ? "bg-slate-700 text-gray-200 border-slate-600" : "bg-gray-200 text-gray-800 border-gray-300"
                                    )}>
                                        {customer.contact[0].toUpperCase()}
                                    </AvatarFallback>
                                </Avatar>
                                <span className="text-sm font-semibold">{customer.contact}</span>
                                <span className={cn(
                                    "text-xs",
                                    dark ? "text-gray-400" : "text-gray-500"
                                )}>{customer.name}</span>
                            </div>
                            <span className={cn(
                                "text-xs font-semibold rounded-full px-2.5 py-1 border",
                                dark ? "bg-slate-700 border-slate-600" : "bg-gray-100 border-gray-200"
                            )}>{customer.email}</span>
                            <Button 
                                variant="ghost" 
                                onClick={() => handleCustomerEdit(index)}
                                className={dark ? "text-gray-200 hover:text-gray-100" : "text-gray-800 hover:text-gray-600"}
                            >
                                Edit
                            </Button>
                        </div>
                    </HoverCardTrigger>
                    <HoverCardContent 
                        side="top" 
                        sideOffset={2} 
                        className={cn(
                            dark ? "bg-slate-700 border-slate-600 text-gray-200" : "bg-white border-gray-200 text-gray-800"
                        )}
                    >
                        <div className="flex flex-col">
                            <span className="text-xs font-semibold">Phone #: {customer.phone}</span>
                            <span className="text-xs font-semibold">Address: {customer.address}</span>
                        </div>
                    </HoverCardContent>
                </HoverCard>
            ))}
        </div>
    );
};

/*
<div className="space-y-2">
                <p className="text-gray-600"><span className="font-semibold">Name:</span> {customer.name}</p>
                <p className="text-gray-600"><span className="font-semibold">Address:</span> {customer.address}</p>
                <p className="text-gray-600"><span className="font-semibold">Contact:</span> {customer.contact}</p>
                <p className="text-gray-600"><span className="font-semibold">Phone:</span> {customer.phone}</p>
                <p className="text-gray-600"><span className="font-semibold">Email:</span> {customer.email}</p>
            </div>
            <button
                onClick={handleCustomerEdit}
                className="bg-gray-500 text-white p-2 rounded mt-6 w-full hover:bg-yellow-600 transition duration-200"
            >
                Edit Customer
            </button>
*/

export default Customer;