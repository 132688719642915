import React, { useRef, useEffect, useState, useCallback } from 'react';
import * as pdfjsLib from 'pdfjs-dist';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.mjs`;

if (typeof Promise.withResolvers === 'undefined') {
  if (window) {
      window.Promise.withResolvers = function () {
          let resolve, reject;
          const promise = new Promise((res, rej) => {
              resolve = res;
              reject = rej;
          });
          return { promise, resolve, reject };
      };
  }
}

pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/legacy/build/pdf.worker.min.mjs',
  import.meta.url
).toString();

const PDFViewer = ({ pdfUrl, boundingBoxes }) => {
  const canvasRef = useRef(null);
  const [pdfDocument, setPdfDocument] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [scale, setScale] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [viewport, setViewport] = useState(null);

  // Effect to load the PDF whenever pdfUrl changes
  useEffect(() => {
    const loadPDF = async () => {
      try {
        setIsLoading(true);
        setError(null);
        setPdfDocument(null);
        setCurrentPage(1);
        
        const loadingTask = pdfjsLib.getDocument(pdfUrl);
        const pdf = await loadingTask.promise;
        setPdfDocument(pdf);
        setNumPages(pdf.numPages);
      } catch (error) {
        console.error('Error loading PDF:', error);
        setError('Failed to load PDF. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    loadPDF();
  }, [pdfUrl]);

  const renderPage = useCallback(async () => {
    if (!pdfDocument) return;

    try {
      setIsLoading(true);
      const page = await pdfDocument.getPage(currentPage);
      const newViewport = page.getViewport({ scale });
      setViewport(newViewport);

      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.height = newViewport.height;
      canvas.width = newViewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: newViewport,
      };

      await page.render(renderContext).promise;
      drawBoundingBoxes(context, newViewport);
    } catch (error) {
      console.error('Error rendering PDF page:', error);
      setError('Failed to render PDF page. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }, [pdfDocument, currentPage, scale]);

  const drawBoundingBoxes = useCallback((context, viewport) => {
    boundingBoxes.forEach((box) => {
      context.beginPath();
      context.rect(
        box.Left * viewport.width,
        box.Top * viewport.height,
        box.Width * viewport.width,
        box.Height * viewport.height
      );
      context.strokeStyle = 'red';
      context.lineWidth = 2;
      context.stroke();
    });
  }, [boundingBoxes]);

  // Effect to render the current page
  useEffect(() => {
    renderPage();
  }, [renderPage]);

  const handleZoomIn = () => setScale(prevScale => prevScale + 0.2);
  const handleZoomOut = () => setScale(prevScale => Math.max(0.5, prevScale - 0.2));
  const handlePrevPage = () => setCurrentPage(prevPage => Math.max(1, prevPage - 1));
  const handleNextPage = () => setCurrentPage(prevPage => Math.min(numPages, prevPage + 1));

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="relative w-full h-full flex items-center justify-center bg-gray-100 dark:bg-gray-700">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-100 bg-opacity-50">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      )}
      <div className="overflow-auto max-w-full max-h-full">
        <canvas ref={canvasRef} className="mx-auto" />
      </div>
      <div className="absolute bottom-4 left-4 flex space-x-2">
        <button onClick={handleZoomOut} className="bg-blue-500 text-white px-2 py-1 rounded">-</button>
        <button onClick={handleZoomIn} className="bg-blue-500 text-white px-2 py-1 rounded">+</button>
        <button onClick={handlePrevPage} disabled={currentPage === 1} className="bg-blue-500 text-white px-2 py-1 rounded">Prev</button>
        <button onClick={handleNextPage} disabled={currentPage === numPages} className="bg-blue-500 text-white px-2 py-1 rounded">Next</button>
        <span className="text-gray-700 dark:text-gray-300">{`Page ${currentPage} of ${numPages}`}</span>
      </div>
    </div>
  );
};

export default PDFViewer;