import React, { useState, useRef, useEffect, useCallback } from 'react';
import backend from '../api/axios';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { Label } from './ui/label';
import { Card, CardContent, CardHeader, CardTitle } from './ui/card';
import * as pdfjsLib from 'pdfjs-dist';
import { useDarkMode } from './new/DarkContext';
import { cn } from './ui/cn';

pdfjsLib.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjsLib.version}/pdf.worker.mjs`;

const Tasks = () => {
  const { dark } = useDarkMode();
  const [file, setFile] = useState(null);
  const [processedData, setProcessedData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [originalPdfUrl, setOriginalPdfUrl] = useState(null);
  const [generatedPdfUrl, setGeneratedPdfUrl] = useState(null);
  const [originalNumPages, setOriginalNumPages] = useState(null);
  const [generatedNumPages, setGeneratedNumPages] = useState(null);
  const [originalCurrentPage, setOriginalCurrentPage] = useState(1);
  const [generatedCurrentPage, setGeneratedCurrentPage] = useState(1);
  const [originalScale, setOriginalScale] = useState(1);
  const [generatedScale, setGeneratedScale] = useState(1);
  const [originalPdfDocument, setOriginalPdfDocument] = useState(null);
  const [generatedPdfDocument, setGeneratedPdfDocument] = useState(null);
  const originalCanvasRef = useRef(null);
  const generatedCanvasRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please upload a PDF file');
      return;
    }

    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await backend.post('/process-quote', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      setProcessedData(response.data.processed_data);
      setOriginalPdfUrl(URL.createObjectURL(file));
      setGeneratedPdfUrl(response.data.generated_pdf_url);
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during processing');
    } finally {
      setIsLoading(false);
    }
  };

  const loadPDF = useCallback(async (url, isOriginal) => {
    try {
      const loadingTask = pdfjsLib.getDocument(url);
      const pdf = await loadingTask.promise;
      if (isOriginal) {
        setOriginalPdfDocument(pdf);
        setOriginalNumPages(pdf.numPages);
        setOriginalCurrentPage(1);
      } else {
        setGeneratedPdfDocument(pdf);
        setGeneratedNumPages(pdf.numPages);
        setGeneratedCurrentPage(1);
      }
    } catch (error) {
      console.error('Error loading PDF:', error);
    }
  }, []);

  useEffect(() => {
    if (originalPdfUrl) {
      loadPDF(originalPdfUrl, true);
    }
  }, [originalPdfUrl, loadPDF]);

  useEffect(() => {
    if (generatedPdfUrl) {
      loadPDF(generatedPdfUrl, false);
    }
  }, [generatedPdfUrl, loadPDF]);

  const renderPage = useCallback(async (isOriginal) => {
    const pdfDocument = isOriginal ? originalPdfDocument : generatedPdfDocument;
    const currentPage = isOriginal ? originalCurrentPage : generatedCurrentPage;
    const scale = isOriginal ? originalScale : generatedScale;
    const canvasRef = isOriginal ? originalCanvasRef : generatedCanvasRef;

    if (!pdfDocument) return;

    try {
      const page = await pdfDocument.getPage(currentPage);
      const viewport = page.getViewport({ scale });
      const canvas = canvasRef.current;
      const context = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      // Cancel any ongoing render operation
      if (canvasRef.current.renderingTask) {
        canvasRef.current.renderingTask.cancel();
      }

      // Start a new render operation and store the task
      canvasRef.current.renderingTask = page.render(renderContext);
      await canvasRef.current.renderingTask.promise;
    } catch (error) {
      if (error.name !== 'RenderingCancelledException') {
        console.error('Error rendering PDF page:', error);
      }
    }
  }, [originalPdfDocument, generatedPdfDocument, originalCurrentPage, generatedCurrentPage, originalScale, generatedScale]);

  useEffect(() => {
    renderPage(true);
  }, [renderPage, originalPdfDocument, originalCurrentPage, originalScale]);

  useEffect(() => {
    renderPage(false);
  }, [renderPage, generatedPdfDocument, generatedCurrentPage, generatedScale]);

  const handleZoomIn = (isOriginal) => isOriginal ? setOriginalScale(prevScale => prevScale + 0.2) : setGeneratedScale(prevScale => prevScale + 0.2);
  const handleZoomOut = (isOriginal) => isOriginal ? setOriginalScale(prevScale => Math.max(0.5, prevScale - 0.2)) : setGeneratedScale(prevScale => Math.max(0.5, prevScale - 0.2));
  const handlePrevPage = (isOriginal) => isOriginal ? setOriginalCurrentPage(prevPage => Math.max(1, prevPage - 1)) : setGeneratedCurrentPage(prevPage => Math.max(1, prevPage - 1));
  const handleNextPage = (isOriginal) => isOriginal ? setOriginalCurrentPage(prevPage => Math.min(originalNumPages, prevPage + 1)) : setGeneratedCurrentPage(prevPage => Math.min(generatedNumPages, prevPage + 1));

  const handleDownload = () => {
    if (generatedPdfUrl) {
      window.open(generatedPdfUrl, '_blank');
    }
  };

  const renderProcessedData = () => {
    console.log("Raw processedData:", processedData);
  
    if (!processedData) {
      return null;
    }
  
    let data = processedData;
    if (typeof processedData === 'string') {
      try {
        data = JSON.parse(processedData);
      } catch (error) {
        console.error("Error parsing processedData:", error);
        return <div>Error parsing data</div>;
      }
    }

    console.log("Parsed data:", data);

    return (
      <div className={cn(
        "flex flex-col p-4",
        dark ? "bg-slate-900 text-gray-200" : "bg-white text-gray-800"
    )}>
        <h3 className="text-2xl font-semibold mb-6 text-gray-900 dark:text-white border-b pb-2">Quote Summary</h3>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
          <div>
            <h4 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">Bill To</h4>
            <p className="text-gray-600 dark:text-gray-400">{data.deliveree_name}</p>
            <p className="text-gray-600 dark:text-gray-400">{data.bill_to_address_line_1}</p>
            <p className="text-gray-600 dark:text-gray-400">{data.bill_to_address_line_2}</p>
          </div>
          <div>
            <h4 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">Quote Details</h4>
            <p className="text-gray-600 dark:text-gray-400"><span className="font-medium">Quote Number:</span> {data.quote_number}</p>
            <p className="text-gray-600 dark:text-gray-400"><span className="font-medium">Quote Date:</span> {data.quote_date}</p>
          </div>
        </div>
        
        <div className="mb-6">
          <h4 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">Items</h4>
          <table className="min-w-full bg-white dark:bg-gray-700 rounded-lg overflow-hidden">
            <thead className="bg-gray-100 dark:bg-gray-600">
              <tr>
                <th className="py-2 px-4 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Description</th>
                <th className="py-2 px-4 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Quantity</th>
                <th className="py-2 px-4 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Unit Price</th>
                <th className="py-2 px-4 text-right text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider">Extended Price</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 dark:divide-gray-600">
              {data.items && data.items.map((item, index) => (
                <tr key={index} className={index % 2 === 0 ? 'bg-gray-50 dark:bg-gray-800' : ''}>
                  <td className="py-2 px-4 text-sm text-gray-900 dark:text-gray-100">{item.item_description}</td>
                  <td className="py-2 px-4 text-sm text-gray-900 dark:text-gray-100 text-right">{item.quantity}</td>
                  <td className="py-2 px-4 text-sm text-gray-900 dark:text-gray-100 text-right">${parseFloat(item.unit_price).toFixed(2)}</td>
                  <td className="py-2 px-4 text-sm text-gray-900 dark:text-gray-100 text-right">${parseFloat(item.extended_price).toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
            <tfoot className="bg-gray-100 dark:bg-gray-600 font-semibold">
              <tr>
                <td colSpan="3" className="py-2 px-4 text-right text-sm text-gray-700 dark:text-gray-300">Total:</td>
                <td className="py-2 px-4 text-right text-sm text-gray-900 dark:text-gray-100">
                  ${parseFloat(data.total_cost).toFixed(2)}
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        
        {data.notes && (
          <div>
            <h4 className="text-lg font-semibold mb-2 text-gray-700 dark:text-gray-300">Notes</h4>
            <p className="whitespace-pre-wrap text-sm text-gray-600 dark:text-gray-400">{data.notes}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="container mx-auto p-6">
      <Card className="bg-white dark:bg-gray-800 text-gray-900 dark:text-white">
        <CardHeader>
          <CardTitle className="text-2xl">Process Quote and Generate PO</CardTitle>
        </CardHeader>
        <CardContent>
          <form onSubmit={handleSubmit} className="space-y-4 mb-4">
            <div className="space-y-2">
              <Label htmlFor="file" className="text-gray-900 dark:text-white">Upload Quote (PDF)</Label>
              <Input
                id="file"
                type="file"
                accept=".pdf"
                onChange={(e) => setFile(e.target.files[0])}
                required
                className="bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white border-gray-300 dark:border-gray-600"
              />
            </div>
            <Button 
              type="submit" 
              disabled={isLoading}
              className="bg-blue-600 hover:bg-blue-700 text-white"
            >
              {isLoading ? 'Processing...' : 'Process Quote'}
            </Button>
          </form>
          <div className="flex flex-col lg:flex-row space-y-4 lg:space-y-0 lg:space-x-4">
            <div className="w-full lg:w-1/2 h-[600px] border-2 border-gray-300 dark:border-gray-600 rounded-lg overflow-hidden">
              {originalPdfUrl ? (
                <div className="relative w-full h-full flex items-center justify-center bg-gray-100 dark:bg-gray-700">
                  <div className="overflow-auto max-w-full max-h-full">
                    <canvas ref={originalCanvasRef} className="mx-auto" />
                  </div>
                  <div className="absolute bottom-4 left-4 flex space-x-2">
                    <button onClick={() => handleZoomOut(true)} className="bg-blue-500 text-white px-2 py-1 rounded">-</button>
                    <button onClick={() => handleZoomIn(true)} className="bg-blue-500 text-white px-2 py-1 rounded">+</button>
                    <button onClick={() => handlePrevPage(true)} disabled={originalCurrentPage === 1} className="bg-blue-500 text-white px-2 py-1 rounded">Prev</button>
                    <button onClick={() => handleNextPage(true)} disabled={originalCurrentPage === originalNumPages} className="bg-blue-500 text-white px-2 py-1 rounded">Next</button>
                    <span className="text-gray-700 dark:text-gray-300">{`Page ${originalCurrentPage} of ${originalNumPages}`}</span>
                  </div>
                </div>
              ) : (
                <div className="w-full h-full flex items-center justify-center bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400">
                  No PDF uploaded
                </div>
              )}
            </div>
            <div className="w-full lg:w-1/2 h-[600px] border-2 border-gray-300 dark:border-gray-600 rounded-lg overflow-hidden">
              {generatedPdfUrl ? (
                <div className="relative w-full h-full flex items-center justify-center bg-gray-100 dark:bg-gray-700">
                  <div className="overflow-auto max-w-full max-h-full">
                    <canvas ref={generatedCanvasRef} className="mx-auto" />
                  </div>
                  <div className="absolute bottom-4 left-4 flex space-x-2">
                    <button onClick={() => handleZoomOut(false)} className="bg-blue-500 text-white px-2 py-1 rounded">-</button>
                    <button onClick={() => handleZoomIn(false)} className="bg-blue-500 text-white px-2 py-1 rounded">+</button>
                    <button onClick={() => handlePrevPage(false)} disabled={generatedCurrentPage === 1} className="bg-blue-500 text-white px-2 py-1 rounded">Prev</button>
                    <button onClick={() => handleNextPage(false)} disabled={generatedCurrentPage === generatedNumPages} className="bg-blue-500 text-white px-2 py-1 rounded">Next</button>
                    <span className="text-gray-700 dark:text-gray-300">{`Page ${generatedCurrentPage} of ${generatedNumPages}`}</span>
                    <button onClick={handleDownload} className="bg-blue-500 text-white px-2 py-1 rounded">Download</button>
                  </div>
                </div>
              ) : (
                <div className="h-full flex items-center justify-center bg-gray-100 dark:bg-gray-700 text-gray-500 dark:text-gray-400 rounded-lg">
                  No generated PO yet
                </div>
              )}
            </div>
          </div>
          {processedData && renderProcessedData()}
        </CardContent>
      </Card>
    </div>
  );
};

export default Tasks;