import React, { useState } from 'react';
import { Settings, User, Bell } from 'lucide-react';
import { supabase } from '../supabaseClient';
import { useNavigate } from 'react-router-dom';

import { Avatar, AvatarFallback, AvatarImage } from './ui/avatar';
import {
    DropdownMenu,
    DropdownMenuTrigger,
    DropdownMenuContent,
    DropdownMenuLabel,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuSeparator
} from './ui/dropdown-menu';
import { useDarkMode } from './new/DarkContext';
import { cn } from './ui/cn';

function UserDetails(user) {
    const navigate = useNavigate();
    const handleSignOut = async () => {
        await supabase.auth.signOut();
        window.location.reload(); // Or any other sign out handling
    };

    const { dark } = useDarkMode()

    return (
        <div className={cn(
            "flex items-center justify-center",
            dark ? "bg-slate-900" : "bg-white"
        )}>
        <DropdownMenu>
            <DropdownMenuTrigger>
                <Avatar>
                    <AvatarImage />
                    <AvatarFallback className={cn(
                        dark ? "bg-slate-700" : "bg-gray-200"
                    )}>
                    <span className={cn(
                        dark ? "text-gray-200" : "text-gray-800"
                    )}>{user?.user_metadata?.name ? user.user_metadata.name.split(' ').map(n => n[0]).join('').toUpperCase() : 'U'}</span>
                    </AvatarFallback>
                </Avatar>
            </DropdownMenuTrigger>
            <DropdownMenuContent className={cn(
                "w-56",
                dark && "bg-slate-700 border-slate-600"
            )}>
                <DropdownMenuLabel className={dark && "text-gray-200"}>My Account</DropdownMenuLabel>
                <DropdownMenuSeparator className={dark && "bg-slate-600"} />
                <DropdownMenuGroup>
                    <DropdownMenuItem onClick={() => navigate('/settings')} className={cn(
                        dark && "text-gray-200 focus:bg-slate-600 focus:text-gray-200", 
                        "cursor-pointer"
                    )}>
                        <Settings className="mr-2 h-4 w-4" />
                        <span>Settings</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem className={cn(
                        dark && "text-gray-200 focus:bg-slate-600 focus:text-gray-200", 
                        "cursor-pointer"
                    )}>
                        <Bell className="mr-2 h-4 w-4" />
                        <span>Notifications</span>
                    </DropdownMenuItem>
                    <DropdownMenuItem onClick={handleSignOut} className={cn(
                        dark && "text-gray-200 focus:bg-slate-600 focus:text-gray-200", 
                        "cursor-pointer"
                    )}>
                        <User className="mr-2 h-4 w-4" />
                        <span>Sign Out</span>
                    </DropdownMenuItem>
                </DropdownMenuGroup>
            </DropdownMenuContent>
        </DropdownMenu>
    </div>
    );
}

export default UserDetails;