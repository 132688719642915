import React, { useState, useEffect } from 'react';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator
} from '../ui/command';
import { Search, FolderX, Calendar, Package, User, Loader2, X } from 'lucide-react';
import backend from '../../api/axios';
import { supabase } from '../../supabaseClient';
import ReactMarkdown from 'react-markdown';
import { useNavigate } from 'react-router-dom';
import { useDarkMode } from '../new/DarkContext';
import { cn } from '../ui/cn';
import { Button } from '../ui/button';

const options = [
  { id: 1, text: 'Date', type: 'date' },
  { id: 2, text: 'Supplier', type: 'supplier' },
  { id: 3, text: 'Person Assigned', type: 'person' },
];

const SearchCommandPopover = () => {
  const { dark } = useDarkMode();
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [optionResults, setOptionResults] = useState(options);
  const [searches, setSearches] = useState([]);
  const [userId, setUserId] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [description, setDescription] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingDescription, setIsLoadingDescription] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [suppliers, setSuppliers] = useState([]);
  const [selectedSupplier, setSelectedSupplier] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const navigate = useNavigate();
  const [showUpdatePrompt, setShowUpdatePrompt] = useState(false);
  const [updatedResults, setUpdatedResults] = useState([]);

  const renderDescription = (desc) => {
    if (!desc || Object.keys(desc).length === 0) {
      return null;
    }
  
    return (
      <div className="space-y-4">
        {Object.entries(desc).map(([key, value]) => {
          if (value) {  // Only render non-empty values
            return (
              <div key={key} className={cn(
                "p-3 rounded-md shadow",
                dark ? "bg-gray-700 text-white" : "bg-white text-gray-800"
              )}>
                <span className="font-semibold">{key.replace('_', ' ').toUpperCase()}:</span>{' '}
                <span>{value}</span>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };

  const TicketParagraph = ({ children, component = 'span', ...props }) => {
    const Component = component;
    const processedChildren = React.Children.map(children, (child) => {
      if (typeof child === 'string') {
        return processText(child);
      } else if (child.props && child.props.children) {
        return React.cloneElement(child, {
          children: React.Children.map(child.props.children, (nestedChild) => 
            typeof nestedChild === 'string' ? processText(nestedChild) : nestedChild
          )
        });
      }
      return child;
    });

    return <Component {...props}>{processedChildren}</Component>;
  };

  const processText = (text) => {
    const parts = text.split(/(\[UUID: [^\]]+\])/g);
    return parts.map((part, index) => {
      const match = part.match(/\[UUID: ([^\]]+)\]/);
      if (match) {
        const ticketId = match[1];
        return (
          <span
            key={index}
            className="text-blue-600 cursor-pointer hover:underline"
            onClick={(e) => {
              e.stopPropagation();
              handleTicketIdClick(ticketId);
            }}
          >
            {ticketId}
          </span>
        );
      }
      return part;
    });
  };

  const handleTicketIdClick = (ticketId) => {
    const ticket = searchResults.find(result => result.ticket.id === ticketId);
    if (ticket) {
      handleResultClick(ticket);
    } else {
      console.log(`Ticket with ID ${ticketId} not found in search results`);
    }
  };

  useEffect(() => {
    const fetchUser = async () => {
      const { data, error } = await supabase.auth.getUser();
      if (data) {
        setUserId(data.user.id);
      } else {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, []);

  useEffect(() => {
    if (userId) {
      const fetchRecentSearches = async () => {
        try {
          const response = await backend.get(`/searches?user_id=${userId}`);
          setSearches(response.data);
        } catch (error) {
          console.error('Error fetching recent searches:', error);
        }
      };

      fetchRecentSearches();
    }
  }, [userId]);

  useEffect(() => {
    const fetchSearchResults = () => {
      if (query.trim() === '') {
        setOptionResults(options);
        return;
      }

      const results = options.filter(option =>
        option.text.toLowerCase().includes(query.toLowerCase())
      );
      setOptionResults(results);
    };

    const debounceTimeout = setTimeout(fetchSearchResults, 100);
    return () => clearTimeout(debounceTimeout);
  }, [query]);

  const handleKeyPress = async (e) => {
    if (e.key === 'Enter') {
      setIsLoading(true);
      setShowResults(true);
      setDescription(null);
      setSearchResults([]);
      try {
        // Fetch the search results
        const resultsResponse = await backend.post(`/search_files`, { 
          query, 
          startDate, 
          endDate, 
          supplier: selectedSupplier
        });
        const results = resultsResponse.data.results.map((result, index) => ({
          id: index,
          type: result.type,
          data: result.data,
          ticket: result.ticket,
          inclusions: result.inclusions,
          similarity: result.similarity,
        }));
        setSearchResults(results);
        setIsLoading(false);

        // Fetch the description separately, passing the search results
        setIsLoadingDescription(true);
        const descriptionResponse = await backend.post(`/search_description`, { 
          query, 
          startDate, 
          endDate, 
          supplier: selectedSupplier,
          searchResults: results
        });
        setDescription(descriptionResponse.data.description);
        // Store updated results and show prompt
        if (descriptionResponse.data.updatedResults) {
          setUpdatedResults(descriptionResponse.data.updatedResults);
          setShowUpdatePrompt(true);
        }
        setIsLoadingDescription(false);

        // Update recent searches
        await backend.post('/searches', { user_id: userId, search: query });
        const recentSearchesResponse = await backend.get(`/searches?user_id=${userId}`);
        setSearches(recentSearchesResponse.data);
      } catch (error) {
        console.error('Error fetching search results:', error);
        setIsLoading(false);
        setIsLoadingDescription(false);
      }
    }
  };

  const handleUpdateResults = () => {
    setSearchResults(updatedResults);
    setShowUpdatePrompt(false);
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setShowResults(false);
  };

  const handleResultClick = (result) => {
    console.log(result);
    setOpen(false);
    navigate(`/tickets/${result.ticket.id}`);
  };

  return (
    <div className={cn(
        "hidden md:block",
        dark ? "bg-slate-900" : "bg-white"
    )}>
      <CommandDialog 
        open={open} 
        onOpenChange={setOpen} 
        dialogClassName={cn(
          "border-slate-600",
          showResults ? 'w-[800px] max-w-[80vw]' : '',
          dark ? 'bg-slate-800' : 'bg-white'
        )}
      >
        <CommandInput
          placeholder="Search tickets, POs and file attachments..."
          wrapperClassName={cn(
            "border-b",
            dark ? "bg-slate-700 border-slate-600 text-gray-200" : "bg-white border-gray-200 text-gray-800"
          )}
          onChangeCapture={handleSearchChange}
          value={query}
          onKeyDown={handleKeyPress}
        />
        <CommandList className={cn(
          showResults ? 'max-h-[70vh] overflow-y-auto' : '',
          dark ? 'bg-slate-700 border-b-slate-600' : 'bg-white border-b-gray-200'
        )}>
          {!showResults ? (
            <>
              <CommandEmpty className="flex items-center justify-center p-10">
                <div className={cn(
                  "flex flex-col gap-2 items-center justify-center",
                  dark ? "text-gray-200" : "text-gray-800"
                )}>
                  <FolderX className="size-10" />
                  <span className="font-semibold text-sm">Nothing found...</span>
                </div>
              </CommandEmpty>
              <CommandGroup heading="Recent Searches">
                {searches.map((search, index) => (
                  <CommandItem 
                    key={index} 
                    className={cn(
                      "h-9",
                      dark ? "aria-selected:bg-slate-600 text-gray-200 aria-selected:text-gray-200" : "aria-selected:bg-gray-100 text-gray-800 aria-selected:text-gray-800"
                    )} 
                    onClickCapture={() => setQuery(search.search)}
                  >
                    {search.search}
                  </CommandItem>
                ))}
              </CommandGroup>
            </>
          ) : (
            <div className="p-4 overflow-y-auto flex-grow">
              {isLoading ? (
                <div className="flex items-center justify-center h-full">
                  <Loader2 className="w-8 h-8 animate-spin text-primary" />
                </div>
              ) : (
                <>
                  {isLoadingDescription ? (
                    <div className={cn(
                      "mb-6 p-4 rounded-lg border flex items-center justify-center",
                      dark ? "bg-slate-600 border-slate-600" : "bg-gray-100 border-gray-200"
                    )}>
                      <Loader2 className="w-6 h-6 animate-spin text-primary mr-2" />
                      <span className={dark ? "text-gray-200" : "text-gray-800"}>Generating summary...</span>
                    </div>
                  ) : description ? (
                    <div className={cn(
                      "mb-6 p-4 rounded-lg border",
                      dark ? "bg-slate-600 border-slate-600" : "bg-gray-100 border-gray-200"
                    )}>
                      <h3 className={cn(
                        "text-lg font-semibold mb-2",
                        dark ? "text-gray-200" : "text-gray-800"
                      )}>Summary of Results</h3>
                      <div className={dark ? "text-gray-200" : "text-gray-800"}>
                        {renderDescription(description)}
                      </div>
                    </div>
                  ) : null}
                  {searchResults.map((result, index) => (
                    <div 
                      key={index} 
                      className={cn(
                        "mb-4 p-4 border rounded-lg transition cursor-pointer",
                        dark ? "bg-slate-800 border-slate-600 hover:bg-slate-700" : "bg-white border-gray-200 hover:bg-gray-100"
                      )} 
                      onClick={() => handleResultClick(result)}
                    >
                      <div className="flex justify-between items-center mb-2">
                        <span className={cn(
                          "font-semibold",
                          dark ? "text-gray-200" : "text-gray-800"
                        )}>Ticket ID: {result.ticket.id}</span>
                        <span className="text-sm text-gray-400">{result.ticket.status}</span>
                      </div>
                      <p className={cn(
                        "mb-2",
                        dark ? "text-gray-300" : "text-gray-600"
                      )}>{result.ticket.description}</p>
                      <div className="flex flex-wrap gap-2 text-sm">
                        <span className={cn(
                          "px-2 py-1 rounded",
                          dark ? "bg-slate-700 text-gray-300" : "bg-gray-200 text-gray-700"
                        )}>Supplier: {result.ticket.supplier}</span>
                        <span className={cn(
                          "px-2 py-1 rounded",
                          dark ? "bg-slate-700 text-gray-300" : "bg-gray-200 text-gray-700"
                        )}>Assigned: {result.ticket.person_assigned}</span>
                        <span className={cn(
                          "px-2 py-1 rounded",
                          dark ? "bg-slate-700 text-gray-300" : "bg-gray-200 text-gray-700"
                        )}>Quantity: {result.ticket.quantity}</span>
                        <span className={cn(
                          "px-2 py-1 rounded",
                          dark ? "bg-slate-700 text-gray-300" : "bg-gray-200 text-gray-700"
                        )}>Net Price: ${result.ticket.net_price}</span>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          )}
          {showUpdatePrompt && (
            <div className={cn(
              "absolute top-10 right-2 p-2 rounded-md shadow-md z-10 w-64",
              dark ? "bg-slate-700 text-gray-200" : "bg-white text-gray-800"
            )}>
              <button 
                onClick={() => setShowUpdatePrompt(false)}
                className="absolute top-1 right-1 text-gray-500 hover:text-gray-700"
              >
                <X size={16} />
              </button>
              <p className="text-sm mb-2">Refined results available. Update?</p>
              <div className="flex justify-end space-x-2">
                <Button 
                  variant={!dark ? "outline" : "secondary"}
                  size="sm" 
                  onClick={() => setShowUpdatePrompt(false)}
                >
                  No
                </Button>
                <Button 
                  size="sm" 
                  onClick={handleUpdateResults}
                >
                  Yes
                </Button>
              </div>
            </div>
          )}
        </CommandList>
      </CommandDialog>
      <button 
        className={cn(
          "flex items-center px-4 w-[450px] border-[1.4px] rounded-md",
          dark ? "border-slate-700 bg-slate-800 text-gray-200" : "border-gray-300 bg-white text-gray-800"
        )} 
        onClick={() => setOpen(true)}
      >
        <Search className="size-4" />
        <div className="flex items-center text-xs px-3 py-2 h-9 text-nowrap">Search tickets, POs and file attachments...</div>
      </button>
    </div>
  );
}

export default SearchCommandPopover;