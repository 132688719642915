import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTrigger } from './ui/dialog';
import { Button } from './ui/button';

const ItemDetail = ({ searchQuery }) => {
    const [quantity, setQuantity] = useState(0);

    const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
    };

    const handleOrder = () => {
        alert(`Ordered ${quantity} units of item #840515`);
    };

    return (
        <div className="flex flex-col gap-4 px-6">
            <h2 className="text-2xl font-semibold mb-4 dark:text-gray-200">Item #840515</h2>
            <div className="flex flex-col gap-2 text-gray-600 dark:text-gray-400">
                <div className="flex flex-col text-xs font-semibold">
                    <span>High Tensile Steel Wire</span>
                    <span>2000/2150/350</span>
                </div>
                <div className="flex justify-between ">
                    <span className="dark:text-gray-200 font-semibold">Availability:</span>
                    <span className="text-green-500 font-semibold">500</span>
                </div>
                <div className="flex justify-between ">
                    <span className="dark:text-gray-200 font-semibold">Company Stock:</span>
                    <span className="text-green-500 font-semibold">324</span>
                </div>
                <div className="flex justify-between ">
                    <span className="dark:text-gray-200 font-semibold">P21 Price:</span>
                    <span className="text-green-500 font-semibold">$2.00</span>
                </div>
                <div className="flex justify-between ">
                    <span className="dark:text-gray-200 font-semibold">Moving Average Cost:</span>
                    <span className="text-green-500 font-semibold">$2.50</span>
                </div>
            </div>
            <Dialog>
                <DialogTrigger asChild>
                    <Button variant="outline" className="dark:bg-slate-600 dark:hover:bg-slate-700 dark:text-gray-200">
                        View History
                    </Button>
                </DialogTrigger>
                <DialogContent>
                    <div className="flex flex-col gap-4">
                        <h3 className="text-lg font-semibold text-gray-700">Sales History</h3>
                        <ul className="flex flex-col gap-2 text-gray-600">
                            <li className="flex items-center gap-4">
                                <span className="px-3 py-1.5 border border-black rounded-lg font-semibold">2/12/21</span>
                                <span>263564</span>
                                <span>$2.00</span>
                            </li>
                            <li className="flex items-center gap-4">
                                <span className="px-3 py-1.5 border border-black rounded-lg font-semibold">2/12/21</span>
                                <span>263565</span>
                                <span>$2.00</span>
                            </li>
                            <li className="flex items-center gap-4">
                                <span className="px-3 py-1.5 border border-black rounded-lg font-semibold">2/12/21</span>
                                <span>263566</span>
                                <span>$2.00</span>
                            </li>
                        </ul>
                    </div>
                    <div className="flex flex-col gap-4">
                        <h3 className="text-lg font-semibold text-gray-700">Quotes History</h3>
                        <ul className="flex flex-col gap-2 text-gray-600">
                            <li className="flex items-center gap-4">
                                <span className="px-3 py-1.5 border border-black rounded-lg font-semibold">2/12/21</span>
                                <span>263764</span>
                                <span>$2.50</span>
                            </li>
                            <li className="flex items-center gap-4">
                                <span className="px-3 py-1.5 border border-black rounded-lg font-semibold">2/12/21</span>
                                <span>263765</span>
                                <span>$2.50</span>
                            </li>
                            <li className="flex items-center gap-4">
                                <span className="px-3 py-1.5 border border-black rounded-lg font-semibold">2/12/21</span>
                                <span>263766</span>
                                <span>$2.50</span>
                            </li>
                        </ul>
                    </div>
                </DialogContent>
            </Dialog>
            <div className="flex flex-col gap-4">
                <input
                    type="number"
                    value={quantity}
                    onChange={handleQuantityChange}
                    className="px-2 py-1 border dark:bg-slate-600 dark:text-gray-200 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-blue-500"
                    placeholder="Enter quantity"
                />
                <Button variant="outline" onClick={handleOrder} className="dark:bg-slate-600 dark:hover:bg-slate-700 dark:text-gray-200">
                    Order
                </Button>
            </div>
        </div>
    );
};

export default ItemDetail;