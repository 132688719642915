import React, { createContext, useContext, useState, useEffect } from "react";

const DarkContext = createContext()

export const DarkModeProvider = ({ children }) => {
    const [dark, setDark] = useState(() => {
        const cache = localStorage.getItem("dark")
        return cache ? JSON.parse(cache) : false
    })

    useEffect(() => {
        localStorage.setItem("dark", JSON.stringify(dark))
    }, [dark])

    return (
        <DarkContext.Provider value={{ dark, setDark }}>
            {children}
        </DarkContext.Provider>
    )
}

export const useDarkMode = () => {
    const context = useContext(DarkContext)

    if (!context) {
        throw new Error("useDarkMode requires DarkModeProvider")
    }

    const { dark, setDark } = context

    const toggleDark = () => {
        setDark(prev => !prev)

        if (dark) {
            document.getElementById("dark-provider-root").classList.remove("dark")
        } else {
            document.getElementById("dark-provider-root").classList.add("dark")
        }
    }

    return {dark, toggleDark}
}