import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faCaretDown, faSpinner } from '@fortawesome/free-solid-svg-icons';
import ReactMarkdown from 'react-markdown';
import backend from '../api/axios';
import { Popover, PopoverContent, PopoverTrigger } from './ui/popover';
import { Bell, RotateCw } from 'lucide-react';

const NotificationMenu = () => {
    const [alerts, setAlerts] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const fetchAlerts = async () => {
        setIsLoading(true);
        try {
            const response = await backend.get('/ticket-alerts');
            setAlerts(response.data.alerts);
        } catch (error) {
            console.error('Error fetching alerts:', error);
            setAlerts('Failed to load alerts. Please try again.');
        } finally {
            setIsLoading(false);
        }
        console.log(alerts)
    };

    // Custom components for markdown rendering
    const components = {
        li: ({ children }) => (
            <li className="">
                {children}
            </li>
        ),
    };

    return (
        <Popover>
            <PopoverTrigger onClick={() => fetchAlerts()}>
                <div className="flex items-center justify-center w-10 h-10">
                    <Bell className="size-4" />
                </div>
            </PopoverTrigger>
            <PopoverContent align="end" className="flex items-center justify-center">
                {isLoading ? (
                    <RotateCw className="size-4 animate-spin m-10" />
                ) : (
                    <ReactMarkdown 
                        className="prose prose-sm max-w-none break-words"
                        components={components}
                    >
                        {alerts}
                    </ReactMarkdown>
                )}
            </PopoverContent>
        </Popover>
    )
};

/*
<div className="relative">
      <button onClick={toggleDropdown} className="flex items-center space-x-2 p-2 bg-gray-100 border border-gray-300 rounded">
        <FontAwesomeIcon icon={faBell} className="text-gray-700" />
        <FontAwesomeIcon icon={faCaretDown} className="text-gray-700" />
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-[32rem] bg-white border border-gray-300 rounded shadow-lg z-50">
          <div className="p-4">
            <h3 className="text-lg font-medium text-gray-900 mb-2">Alerts</h3>
            <div className="max-h-[70vh] overflow-y-auto">
              {isLoading ? (
                <div className="flex justify-center items-center h-24">
                  <FontAwesomeIcon icon={faSpinner} spin className="text-blue-500 text-2xl" />
                </div>
              ) : (
                <ReactMarkdown 
                  className="prose prose-sm max-w-none break-words"
                  components={components}
                >
                  {alerts}
                </ReactMarkdown>
              )}
            </div>
            <button
              onClick={() => setIsOpen(false)}
              className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
*/

export default NotificationMenu;